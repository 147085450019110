import axios from "axios";
import Cookies from "js-cookie";
import { apiRoutesForContext } from "src/routes/apiRoutes/react-api-route-paths";
import { ExecutedActions } from "src/sdk/models/Users";
import { BaseService } from "../services/BaseService";
import { PaginationRequest } from "src/sdk";

const tmp = apiRoutesForContext();
export class UserService extends BaseService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }

    getModulesByUserId(): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.user().getModulesByUserId(),
        })
    }

    getModuleRecordsByUserModuleId(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.user().getModuleRecordsByUserModuleId() + "?userModuleId=" + data.userModuleId + "&pageSize=" + data.pageSize + "&page=" + data.page + "&search=" + data.search + "&sortBy=" + data.sortBy + "&sortOrder=" + data.sortOrder,

        })
    }
    moduleRecordsByUserModuleId(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.user().moduleRecordsByUserModuleId(),
            data: data
        })
    }
    getModuleRecordsByModuleRecordId(moduleRecordId: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.user().getModuleRecordsByModuleRecordId() + "/" + moduleRecordId,
        })
    }
    executedActions(data: ExecutedActions): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.user().executedActions(),
            data: data
        })
    }

    getActionLinkByPublicId(item: any, publicId: string, token: string): Promise<any> {
        return axios({
            method: "get",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: "https://" + item.host + "/fmi/data/vLatest/databases/" + item.database + "/layouts/" + item.layout + "/script/" + item.scriptName + "?script.param=" + publicId,
        })
    }

    filemakerAuthentication(item: any, basicToken: string): Promise<any> {
        let data = JSON.stringify({});
        return axios({
            method: "post",
            // headers: { "Content-Type": "application/json", "Authorization": "Basic QVBJX0FjY2VzczokeW50aGVzaXNAUCE=" },
            headers: { "Content-Type": "application/json", "Authorization": `Basic ${basicToken}=` },
            url: "https://" + item.host + "/fmi/data/v1/databases/" + item.database + "/sessions",
            data: data,
        })
    }

    closeFilemakerSession(item: any, token: string, basicToken: string): Promise<any> {
        let data = JSON.stringify({});
        return axios({
            method: "delete",
            //  headers: { "Content-Type": "application/json", "Authorization": "Basic QVBJX0FjY2VzczokeW50aGVzaXNAUCE=" },
            headers: { "Content-Type": "application/json", "Authorization": `Basic ${basicToken}=` },
            url: "https://" + item.host + "/fmi/data/v1/databases/" + item.database + "/sessions/" + token,
            data: data,
        })
    }
}
