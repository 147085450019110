import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Col, Container, Form, FormFeedback, Input, Row } from "reactstrap";
import { SuperAdminService } from "src/services/SuperAdminService";
import * as Yup from "yup";

const service = new SuperAdminService();
export const CreateUser = () => {
    const history = useHistory();
    const [error, setError] = useState("");

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            role: "User",
            clientConfirmationEmailURI: 'http://webapp.synthesishealthsoftware.com/register-success',
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required("Please enter first name"),
            lastName: Yup.string()
                .required("Please enter last name"),
            email: Yup.string()
                .required("Please enter email"),
        }),
        onSubmit: (values: any) => {

            service.createUser(values)
                .then(function (response) {
                    if (response.data.success === false) {
                        setError("Email already exist!");
                    } else {
                        history.push("/users-managment");
                        toast.success(`You have create new user ${values.firstName} ${values.lastName}!`);
                    }
                })
                .catch(function (error) {
                });
        }
    });

    document.title = "Create user | Synth";
    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Container fluid>
                    <div className="row">
                        <div className="col-12">
                            <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
                            <Link style={{color:"black"}}
                                to="/users-managment"
                                className="nav_link"
                                >
                                <h5>Users management</h5>
                                </Link>
                            <i className="bi bi-chevron-right"></i>
                            <h5 style={{marginLeft:13, opacity:0.6}}>Create User</h5>
                            </div>
                        </div>
                    </div>
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <Row >
                        <Col lg={4} md={5} className="col-xxl-2 p-2">
                            <h5>First name:</h5>
                        </Col>
                        <Col lg={4} md={5} className="col-xxl-10">
                            <Input
                                id="firstName"
                                name="firstName"
                                className="form-control"
                                placeholder="Enter first name"
                                type="text"
                                onChange={validation.handleChange}
                                invalid={
                                    validation.touched.firstName && validation.errors.firstName ? true : false
                                }
                            />
                            {validation.touched.firstName && validation.errors.firstName ? (
                                <FormFeedback type="invalid"><>{validation.errors.firstName}</></FormFeedback>
                            ) : null}
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={4} md={5} className="col-xxl-2 p-2">
                            <h5>Last name:</h5>
                        </Col>
                        <Col lg={4} md={5} className="col-xxl-10">
                            <Input
                                id="lastName"
                                name="lastName"
                                className="form-control"
                                placeholder="Enter last name"
                                type="text"
                                onChange={validation.handleChange}
                                invalid={
                                    validation.touched.lastName && validation.errors.lastName ? true : false
                                }
                            />
                            {validation.touched.lastName && validation.errors.lastName ? (
                                <FormFeedback type="invalid"><>{validation.errors.lastName}</></FormFeedback>
                            ) : null}
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={4} md={5} className="col-xxl-2 p-2">
                            <h5>Email:</h5>
                        </Col>
                        <Col lg={4} md={5} className="col-xxl-10">
                            <Input
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid"><>{validation.errors.email}</></FormFeedback>
                            ) : null}
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={4} md={5} className="col-xxl-2 p-2">
                            <h5>Role:</h5>
                        </Col>
                        <Col lg={4} md={5} className="col-xxl-10">
                            <Input
                                id="role"
                                name="role"
                                className="form-control"
                                type="select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.role || ""}
                            >
                                <option disabled>Choose a type</option>
                                <option value={"SuperAdministrator"}>admin</option>
                                <option value={"User"}>user</option>
                            </Input>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <div className="d-flex flex-row-reverse">
                        <div className="p-1">
                            <button
                                className="btn btn-primary btn-block"
                                style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                        <div className="p-1">
                            <Link
                                className="btn btn-secondary btn-block"
                                to="/users-managment"
                            >
                                Cancel
                            </Link>
                        </div>
                    </div>
                </Container>
            </Form>
        </React.Fragment>
    );
}
CreateUser.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(CreateUser);