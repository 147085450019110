import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { FormGroup, Input, Label } from "reactstrap";
import { UserModules } from "src/sdk";

export const UserModuleColumns = memoizeOne(
    (
        getActions: (userModule: UserModules) => void,
        getFilter: (userModule: UserModules) => void,
        asignedUserModule: (userModule: UserModules, check: boolean) => void,
    ): TableColumn<UserModules>[] => {
        return [

            {
                id: "moduleId",
                selector: (row: any) => row.moduleId,
                sortable: true,
                name: "Module Id",
                width: "20%",
            },
            {
                id: "moduleName",
                selector: (row: any) => row.moduleName,
                sortable: true,
                name: "Name",
            },
            {
                sortable: false,
                cell: (params, index, column, row) => {
                    return <>
                        <div style={{ paddingRight: "40px" }} className="m-auto" >
                            <button
                                className="btn btn-primary btn-block"
                                style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                onClick={() => { getActions(params as UserModules) }}
                                disabled={params.assigned === false ? true : false}
                            >
                                Actions
                            </button>
                        </div>
                        <div>
                            <button
                                className="btn btn-primary btn-block"
                                style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                onClick={() => { getFilter(params as UserModules) }}
                                disabled={params.assigned === false ? true : false}
                            >
                                Filter
                            </button>
                        </div>
                    </>
                },
                width: "220px",
            },
            {
                selector: (row: any) => row.assigned,
                width: "10%",
                cell: (params, index, column, row: any) => {
                    return <>
                        <FormGroup tag="fieldset" className="m-auto">
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        key={params.moduleId}
                                        name="sortable"
                                        type="checkbox"
                                        onChange={(event: any) => {
                                            asignedUserModule(params as UserModules, event.target.checked)
                                        }}
                                        value="false"
                                        defaultChecked={params.assigned}
                                    />
                                </Label>
                            </FormGroup>
                        </FormGroup>
                    </>
                },
            },
        ];
    },
);

