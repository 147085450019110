export interface Modules {
  moduleId?: number;
  name: string
}

export const getModulesInitialValue = (): Modules => {
  return {
    moduleId: 0,
    name: "",
  };
};

export const ModulesData: Modules[] = [
  {
    moduleId: 1,
    name: 'Table 1',
  },
  {
    moduleId: 2,
    name: 'Table 2',
  },
  {
    moduleId: 3,
    name: 'Table 3',
  },

]

export interface Fields {
  moduleId: number,
  fieldId?: number,
  name: string,
  label: string,
  orderNo: number,
  type: string,
  showOnForm: boolean,
  showOnList: boolean,
  sortable: boolean,
  isFilterField?: boolean,
  value?: string
}

export const getFieldsInitialValue = (): Fields => {
  return {
    moduleId: 0,
    fieldId: 0,
    name: "",
    label: "",
    orderNo: 0,
    type: "",
    showOnForm: false,
    showOnList: false,
    sortable: false,
    isFilterField: false,
  };
};

export interface FieldValue {
  [key: string]: any
}

export const getFieldValueInitialValue = (): FieldValue => {
  return {
    "": ""
  };
};

export interface FieldForm {
  [key: string]: any
}

export const getFieldFormInitialValue = (): FieldForm => {
  return {
    "": ""
  };
};

export interface FieldMappings {
  apiMappingId?: number;
  fieldId: number;
  originalFieldName: string;
  apiFieldName: string;
}

export const getFieldMappingsInitialValue = (): FieldMappings => {
  return {
    apiMappingId: 0,
    fieldId: 0,
    originalFieldName: "",
    apiFieldName: "",
  };
};
///////////////////////////////////////////////
export interface Actions {
  moduleId?: number;
  actionId?: number;
  name: string;
  link: string;
  actionType: string;
  showOnList: boolean;
  showOnForm: boolean;
  username?: string;
  password?: string;
  host?: string;
  database?: string;
  layout?: string;
  scriptName?: string;
  scriptParam?: string;
  isModuleAction?: boolean;
}

export const ActionsData: Actions[] = [
  {
    actionId: 12,
    name: "FirstName",
    link: "string",
    actionType: "string",
    showOnForm: true,
    showOnList: false,
    username: "string",
    password: "string",
    host: "string",
    database: "string",
    layout: "string",
    scriptName: "string",
  },
  {
    actionId: 21,
    name: "LastName",
    link: "string",
    actionType: "string",
    showOnForm: true,
    showOnList: false,
    username: "string",
    password: "string",
    host: "string",
    database: "string",
    layout: "string",
    scriptName: "string",
  },
  {
    actionId: 31,
    name: "Year",
    link: "Year",
    actionType: "string",
    showOnForm: true,
    showOnList: false,
    username: "string",
    password: "string",
    host: "string",
    database: "string",
    layout: "string",
    scriptName: "string",
  },
] 


export interface ActionsOptions {
  value: number;
  label: string;
}

export const getActionsOptionsInitialValue =
  (): ActionsOptions => {
    return {
      value: 0,
      label: "",
    };
  };

export interface PaginationRequest {
  moduleId?: number;
  userId?: string;
  userModuleId?: number;
  page: number;
  pageSize: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  externalUserId?: string;
}

export const getPaginationRequestInitialValue =
  (): PaginationRequest => {
    return {
      moduleId: 0,
      userId: "",
      userModuleId: 0,
      page: 0,
      pageSize: 0,
      search: "",
      sortOrder: "",
    };
  };