import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { UserProvider } from "./globals/providers";

// Import Routes all
import { adminRoutes, authRoutes, userRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

//Import layouts
import AdminLayout from './layouts/AdminLayout';
import NonAuthLayout from './layouts/NonAuthLayout';
import UserLayout from './layouts/UserLayout';

const App = () => {

  return (
    <React.Fragment>
      <UserProvider>

        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                isAdminLayout={false}
                exact
              />
            ))}

            {adminRoutes.map((route: any, idx: number) => (
              <Authmiddleware
                path={route.path}
                layout={AdminLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                isAdminLayout={true}
                exact
              />
            ))}

            {userRoutes.map((route: any, idx: number) => (
              <Authmiddleware
                path={route.path}
                layout={UserLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                isAdminLayout={false}
                exact
              />
            ))}
          </Switch>
        </Router>
      </UserProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        theme="colored"
      />
    </React.Fragment>
  );
};

export default App;