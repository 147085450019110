
import React, { useEffect, useState } from "react";
import {
    Dropdown, DropdownMenu, DropdownToggle
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import FontAwesome from 'react-fontawesome';
import { toast } from "react-toastify";
import { ExecutedActions, FieldForm, FieldValue, getFieldFormInitialValue } from "src/sdk";
import { UserService } from "src/services/UserService";
window.Buffer = window.Buffer || require("buffer").Buffer;


export interface DropdownExecutedActionsProps {
    moduleRecordId: any;
    actions: any[];
    setUpdateActions: (value: boolean) => void;
}

const service = new UserService();
const DropdownExecutedActions: React.FC<DropdownExecutedActionsProps> = ({
    moduleRecordId,
    actions,
    setUpdateActions,
}) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false);
    const [name, setName] = useState("Actions");
    const [obj, setObj] = useState<FieldValue>(getFieldFormInitialValue());

    useEffect(() => {
        service.getModuleRecordsByModuleRecordId(moduleRecordId)
            .then(function (response) {
                const obj1: FieldForm = {};
                response.data.item.fieldValueItems.map((e: any) => {
                    let key: string = e.name;
                    obj1[key] = e.value;
                });
                setObj(obj1);
            })
            .catch(function (error) {
            })
    }, [moduleRecordId]);

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item bg-soft-light"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <FontAwesome name="caret-down" />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end" style={{ cursor: "pointer" }}>
                    {actions.filter(a => a.isModuleAction === false).map((element: any, index: any) => {
                        return (<div key={element.actionId}>
                            <div
                                className="dropdown-item"
                                onClick={() => {
                                    const executedActionsData: ExecutedActions = {
                                        actionId: element.actionId,
                                        moduleRecordId: moduleRecordId as number,
                                        editedJson: JSON.stringify(obj),
                                    }
                                    
                                    service.executedActions(executedActionsData)
                                        .then(function (response) {
                                            let username = element.username;
                                            let password = element.password;

                                            let base64encodedData = Buffer.from(username + ':' + password).toString('base64');
                                            /////////////////////////////
                                            service.filemakerAuthentication(element, base64encodedData)
                                                .then(function (fmresponse) {
                                                    console.log(obj);
                                                    let scriptParam = {
                                                        publicId: response.data.item.publicId,
                                                        param: obj.hasOwnProperty(element.scriptParam) ? obj[element.scriptParam] : element.scriptParam
                                                    };
                                                    console.log(scriptParam);
                                                    service.getActionLinkByPublicId(element, JSON.stringify(scriptParam), fmresponse.data.response.token)
                                                        .then(function (response2) {
                                                            service.closeFilemakerSession(element, fmresponse.data.response.token, base64encodedData)
                                                                .then(function (fmresponse2) {
                                                                    setUpdateActions(true);
                                                                    toast.success(`Message: ${JSON.parse(response2.data.response.scriptResult).message}  Status: ${JSON.parse(response2.data.response.scriptResult).status}`);
                                                                })
                                                                .catch(function (error) {
                                                                })
                                                        })
                                                        .catch(function (error) {
                                                        })
                                                })
                                                .catch(function (error) {
                                                })
                                        })
                                        .catch(function (error) {
                                        })
                                }
                                }>
                                <i className="bx bx-user font-size-16 align-middle me-1" />
                                <span>{element.name}</span>
                            </div>
                        </div>

                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment >
    );
};

DropdownExecutedActions.propTypes = {

};
export default withTranslation()(DropdownExecutedActions);
