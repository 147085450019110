import React, { useState } from "react";
import {
    Dropdown, DropdownMenu, DropdownToggle
} from "reactstrap";

import FontAwesome from 'react-fontawesome';
import { Actions } from "src/sdk";


export interface DropdownActionProps {
    values?: Actions;
    onEditAction?: (values: Actions) => void;
    id?: number;
    onDeleteAction?: (id: number) => void;
}

const ActionsDropdown: React.FC<DropdownActionProps> = ({
    values,
    onEditAction,
    id,
    onDeleteAction,
}) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false);
    const [name, setName] = useState("Options");

    const editAction = () => {
        if (onEditAction) onEditAction(values as Actions);
    };

    const deleteAction = () => {
        if (onDeleteAction) onDeleteAction(id as number);
    };
    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item bg-soft-light"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <FontAwesome name="caret-down" />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <div className="dropdown-item" onClick={editAction}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Edit</span>
                    </div>
                    <div className="dropdown-item" onClick={deleteAction}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Delete</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

ActionsDropdown.propTypes = {

};
export default ActionsDropdown;
