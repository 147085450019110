import { boolean } from "yup";

export interface ActionsForUserModule {
    moduleId: number;
    userId: string;
    id?: number;
    view: boolean;
    edit: boolean;
    delete: boolean;
}

export const getActionsForUserModuleInitialValue =
    (): ActionsForUserModule => {
        return {
            moduleId: 0,
            userId: "",
            id: 0,
            view: false,
            edit: false,
            delete: false,
        };
    };

export interface UserActions {
    userActionId?: number;
    actionId: number;
    actionName: string;
    userModuleId: number;
    assigned: boolean;
}
export const getUserActionsInitialValue =
    (): UserActions => {
        return {
            userActionId: 0,
            actionId: 0,
            actionName: "",
            userModuleId: 0,
            assigned: false,
        };
    };

export interface Filter {
    userFilterFieldId?: number,
    value: string,
    fieldId: number,
    fieldName?: string,
    userModuleId?: number
}

export const getFilterInitialValue =
    (): Filter => {
        return {
            userFilterFieldId: 0,
            value: "",
            fieldId: 0,
            fieldName: "",
            userModuleId: 0
        };
    };

export interface UserModules {
    userModuleId?: number;
    userId: string;
    moduleId: number;
    moduleName: string;
    assigned: boolean;
    daysToShow?: number;
    filterByExternalUserId?: boolean;
}

export const getUserModulesInitialValue = (): UserModules => {
    return {
        userModuleId: 0,
        userId: "",
        moduleId: 0,
        moduleName: "",
        assigned: false,
        filterByExternalUserId:false
    };
};

export interface ApproveRegistrationUser {
    userId: string;
    isApproved: boolean;
}