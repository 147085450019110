import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { AuthService } from "src/services/AuthService";
import * as Yup from "yup";
import { PasswordConfirmField } from "./PasswordConfirmField";
import { PasswordField } from "./PasswordField";
import logo from "../../assets/images/logo.png";

const service = new AuthService();
const Register = () => {
  const history = useHistory();
  const [error, setError] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      clientConfirmationEmailURI: 'http://webapp.synthesishealthsoftware.com/register-success', 
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required("Please enter your first name"),
      lastname: Yup.string()
        .required("Please enter your last name"),
      email: Yup.string()
        .required("Please enter your email")
        .email("Valid email required"),
      password: Yup.string()
        .required("This field is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#[\]$%[\]^&[\]*.,;:{}<>+-/_=~`[]()?|]])(?=.{8,})/,
          "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
        ),
      confirmPassword:
        Yup.string()
          .required("Please confirm your password")
          .oneOf([Yup.ref("password")], "Passwords do not match")
    }),
    onSubmit: (values: any) => {
      service.register(values)
        .then(function (response) {
          toast.success("Check your email to activate your account!");
          history.push("/login");

        })
        .catch(function (error) {
          setError("Your email already exist!");
        })
    }
  });

  document.title = "Register | Synth";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4 mt-5" style={{ backgroundColor: "#274755", color: "white" }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <img src={logo} />
                      </div>
                      <hr className="my-4"/>
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                      </div>
                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">First Name</Label>
                          <Input
                            id="firstname"
                            name="firstname"
                            className="form-control"
                            placeholder="Enter your first name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstname || ""}
                            invalid={
                              validation.touched.firstname && validation.errors.firstname ? true : false
                            }
                          />
                          {validation.touched.firstname && validation.errors.firstname ? (
                            <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Last Name</Label>
                          <Input
                            id="lastname"
                            name="lastname"
                            className="form-control"
                            placeholder="Enter your last name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastname || ""}
                            invalid={
                              validation.touched.lastname && validation.errors.lastname ? true : false
                            }
                          />
                          {validation.touched.lastname && validation.errors.lastname ? (
                            <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <PasswordField
                            validation={validation}
                          />
                        </div>
                        <div className="mb-3">
                          <PasswordConfirmField
                            validation={validation}
                          />
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-light w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </Form>
                      <div className="mt-5 text-center">
                        <p className="text-body-secondary mb-0">
                          Already have an account ?{" "}
                          <Link
                            to="/login"
                            className="text-light fw-semibold"
                          >
                            {" "}
                            Login{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
