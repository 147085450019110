import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import '../Style/index.css';

export interface AdminSidebarProps {
  showNav: boolean;
}

const AdminSidebar: React.FC<AdminSidebarProps> = ({
  showNav,
}) => {

  return (
    <React.Fragment>
      <div className={`l-navbar${showNav ? ' l-show' : ''}`}>
        <nav className="custom_nav border-end">
          <div className="container-fluid " >
            <div className="align-items-left text-start">
              <div className="nav_list">
                <Link
                  to="/admin-home"
                  className="nav_logo"
                >
                  <span className="nav_logo-icon">
                    <img src={logo} />
                  </span>
                </Link>
                <Link
                  to="/admin-home"
                  className="nav_link"
                >
                  <i className='bi bi-house-door nav_icon' />
                  <span className="nav_name">  Home </span>
                </Link>
                <Link
                  to="/all-modules"
                  className="nav_link"
                >
                  <i className='bi bi-grid nav_icon' />
                  <span className="nav_name">  Modules </span>
                </Link>
                <Link
                  to="/users-managment"
                  className="nav_link"
                >
                  <i className='bi bi-people nav_icon' />
                  <span className="nav_name">  User's  managment </span>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(AdminSidebar);
