import classnames from "classnames";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Actions, Modules } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import * as Yup from "yup";
import '../../../index.css';

const service = new SuperAdminService()
const CreateAction = () => {
    const location = useLocation();
    const history = useHistory();

    const [activeTab, setactiveTab] = useState(1);
    const [password, setPassword] = useState<boolean>(true);
    const [eye, setEye] = useState<string>("bi bi-eye-slash");
    const [type, setType] = useState<any>("password");
    const [isModule, setIsModule] = useState<boolean>(location.isModule);

    console.log(location);

    function toggleTab(tab: any) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 2) {
                setactiveTab(tab);
            }
        }
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            link: "",
            host: "",
            database: "",
            layout: "",
            scriptName: "",
            scriptParam: "",
            actionType: "Create",
            showOnList: false,
            showOnForm: false,
            username: "",
            password: "",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Please enter name"),
            link: Yup.string()
                .required("Please enter link"),
            host: Yup.string()
                .required("Please enter host"),
            database: Yup.string()
                .required("Please enter database"),
            layout: Yup.string()
                .required("Please enter layout"),
            scriptName: Yup.string()
                .required("Please enter script name"),
            username: Yup.string()
                .required("Please enter username"),
            password: Yup.string()
                .required("Please enter password"),

        }),
        onSubmit: (values: any) => {
            let action: Actions = {
                moduleId: location.state.moduleId,
                name: values.name,
                link: values.link,
                host: values.host,
                database: values.database,
                layout: values.layout,
                scriptName: values.scriptName,
                scriptParam: values.scriptParam,
                actionType: values.actionType,
                isModuleAction: isModule,
                showOnList: JSON.parse(values.showOnList),
                showOnForm: JSON.parse(values.showOnForm),
                username: values.username,
                password: values.password,

            }
            console.log(action);
            service.createAction(action)
                .then(function (response) {
                    history.push({
                        pathname: '/actions-module',
                        state: location.state as Modules,
                    })
                    toast.success(`You have create new action ${values.name}!`);
                })
                .catch(function (error) {
                });
        }
    });


    document.title = "Create action | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid mb-5">
                <CardBody>
                    <div className="row">
                        <div className="col-12">
                            <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
                            <Link style={{color:"black"}}
                                to="/all-modules"
                                className="nav_link"
                                >
                                <h5>Modules</h5>
                                </Link>
                            <i className="bi bi-chevron-right"></i>
                            <Link style={{color:"black", marginLeft:10}}
                                to={{
                                    pathname: '/actions-module',
                                    state: location.state as Modules
                                }}
                                className="nav_link"
                                >
                                <h5>{location.state.name}</h5>
                                </Link>
                            <i className="bi bi-chevron-right"></i>
                            <h5 style={{marginLeft:13, opacity:0.6}}>Create Action</h5>
                            </div>
                        </div>
                    </div>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({
                                        active: activeTab === 1,
                                    })}
                                    onClick={() => {
                                        setactiveTab(1);
                                    }}
                                >
                                    <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Basic info"
                                    >
                                        <i className="bi bi-info-lg"></i>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({
                                        active: activeTab === 2,
                                    })}
                                    onClick={() => {
                                        setactiveTab(2);
                                    }}
                                >
                                    <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Auth"
                                    >
                                        <i className="bi bi-shield-lock"></i>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}
                                >
                                <TabPane tabId={1}>
                                    <div className="text-center mb-4">
                                        <h5>Basic info</h5>
                                        <p className="card-title-desc">Fill all information below</p>
                                    </div>
                                    <Container fluid className="p-5">
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Name:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name && validation.errors.name ? true : false
                                                    }
                                                />
                                                {validation.touched.name && validation.errors.name ? (
                                                    <FormFeedback type="invalid"><>{validation.errors.name}</></FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Link:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="link"
                                                    name="link"
                                                    className="form-control"
                                                    placeholder="Enter link"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.link || ""}
                                                    invalid={
                                                        validation.touched.link && validation.errors.link ? true : false
                                                    }
                                                />
                                                {validation.touched.link && validation.errors.link ? (
                                                    <FormFeedback type="invalid"><>{validation.errors.link}</></FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Host:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="host"
                                                    name="host"
                                                    className="form-control"
                                                    placeholder="Enter host"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.host || ""}
                                                    invalid={
                                                        validation.touched.host && validation.errors.host ? true : false
                                                    }
                                                >
                                                    <option value="" hidden>Select</option>
                                                    <option value="https://dev.synthesishealthsoftware.com">https://dev.synthesishealthsoftware.com</option>
                                                    <option value="https://fm.synthesishealthsoftware.com">https://fm.synthesishealthsoftware.com</option>
                                                    <option value="https://ca.synthesishealthsoftware.com">https://ca.synthesishealthsoftware.com</option>
                                                </Input>
                                                {validation.touched.host && validation.errors.host ? (
                                                    <FormFeedback type="invalid"><>{validation.errors.host}</></FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Database:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="database"
                                                    name="database"
                                                    className="form-control"
                                                    placeholder="Enter database"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.database || ""}
                                                    invalid={
                                                        validation.touched.database && validation.errors.database ? true : false
                                                    }
                                                />
                                                {validation.touched.database && validation.errors.database ? (
                                                    <FormFeedback type="invalid"><>{validation.errors.database}</></FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Layout:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="layout"
                                                    name="layout"
                                                    className="form-control"
                                                    placeholder="Enter layout"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.layout || ""}
                                                    invalid={
                                                        validation.touched.layout && validation.errors.layout ? true : false
                                                    }
                                                />
                                                {validation.touched.layout && validation.errors.layout ? (
                                                    <FormFeedback type="invalid"><>{validation.errors.layout}</></FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Script name:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="scriptName"
                                                    name="scriptName"
                                                    className="form-control"
                                                    placeholder="Enter script name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.scriptName || ""}
                                                    invalid={
                                                        validation.touched.scriptName && validation.errors.scriptName ? true : false
                                                    }
                                                />
                                                {validation.touched.scriptName && validation.errors.scriptName ? (
                                                    <FormFeedback type="invalid"><>{validation.errors.scriptName}</></FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Script parameter:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="scriptParam"
                                                    name="scriptParam"
                                                    className="form-control"
                                                    placeholder="Enter script parameter"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.scriptParam || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Type:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <Input
                                                    id="actionType"
                                                    name="actionType"
                                                    className="form-control"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                >
                                                    <option disabled>Choose a type</option>
                                                    <option>Create</option>
                                                    <option>Edit</option>
                                                    <option>Delete</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                <h5>Show on list:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <FormGroup tag="fieldset" >
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                name="showOnList"
                                                                type="checkbox"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value="false"
                                                            />
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col lg={4} md={5} className="col-xxl-2">
                                                <h5>Show on form:</h5>
                                            </Col>
                                            <Col lg={4} md={5} className="col-xxl-10">
                                                <FormGroup tag="fieldset">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                name="showOnForm"
                                                                type="checkbox"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value="false"
                                                            />
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Container>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <div>
                                        <div className="text-center mb-4">
                                            <h5>Auth</h5>
                                            <p className="card-title-desc">Fill all information below</p>
                                        </div>
                                        <Container fluid className="p-5">
                                            <Row >
                                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                    <h5>Username:</h5>
                                                </Col>
                                                <Col lg={4} md={5} className="col-xxl-10">
                                                    <Input
                                                        id="username"
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Enter username"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ""}
                                                        invalid={
                                                            validation.touched.username && validation.errors.username ? true : false
                                                        }
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid"><>{validation.errors.username}</></FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                    <h5>Password:</h5>
                                                </Col>
                                                <Col lg={4} md={5} className="col-xxl-10">
                                                    <div className="input-group">
                                                        <Input
                                                            name="password"
                                                            type={type}
                                                            className="form-control"
                                                            placeholder="Enter password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className={`btn btn-primary rounded-0 rounded-end  ${eye}`}
                                                                style={{borderColor:"#95CBB2", backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                                                onClick={() => {
                                                                    if (password === true) {
                                                                        setPassword(false);
                                                                        setEye("bi bi-eye");
                                                                        setType("text");
                                                                    } else {
                                                                        setPassword(true);
                                                                        setEye("bi bi-eye-slash");
                                                                        setType("password");
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid"><>{validation.errors.password}</></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </TabPane>
                            </TabContent>
                            <Container fluid>
                                <div className="d-flex flex-row-reverse">
                                    <div className="p-1">
                                        {activeTab === 2 ? (
                                            <button
                                                className="btn btn-primary"
                                                style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        ) : (
                                            <Link
                                                className="btn btn-primary"
                                                style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                                to={{
                                                    pathname: '/create-action',
                                                    state: location.state as Modules
                                                }}
                                                onClick={() => {
                                                    if (activeTab === 1) {
                                                        toggleTab(activeTab + 1);
                                                    }
                                                }}
                                            >
                                                Next <i className="bi bi-chevron-right ms-1"></i>
                                            </Link>)}
                                    </div>
                                    <div className="p-1">
                                        {activeTab === 1 ? (
                                            <Link
                                                className="btn btn-secondary btn-block"
                                                to={{
                                                    pathname: '/actions-module',
                                                    state: location.state as Modules
                                                }}
                                            >
                                                Cancel
                                            </Link>
                                        ) : (
                                            <Link
                                                className="btn btn-secondary"
                                                to={{
                                                    pathname: '/create-action',
                                                    state: location.state as Modules
                                                }}
                                                onClick={() => {
                                                    if (activeTab === 2) {
                                                        toggleTab(activeTab - 1);
                                                    }
                                                }}
                                            >
                                                <i className="bi bi-chevron-left me-1"></i> Previous
                                            </Link>)}
                                    </div>
                                </div>
                            </Container>
                        </Form>
                    </div>
                </CardBody>
            </div>
        </React.Fragment>
    );
};

CreateAction.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(CreateAction);