import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router";
import { Col, Container, Form, FormFeedback, Input, Modal, Row } from "reactstrap";
import { DefaultThemeForReactTable } from "src/components";
import { Fields, Filter, getFieldsInitialValue, User, UserModules } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UserModuleFilterFieldsColumns } from "./Components/UserModuleFilterFieldsColumns";

export interface UserModuleFilterFieldsProps {
    userModule: UserModules;
    filter_center: boolean;
    setFilter_center: (value: boolean) => void;
    tog_filterCenter: () => void;
}

const service = new SuperAdminService()
export const UserModuleFilterFields: React.FC<UserModuleFilterFieldsProps> = ({
    userModule,
    filter_center,
    setFilter_center,
    tog_filterCenter,
}) => {

    const location = useLocation()
    const [fieldsData, setFieldsData] = useState<Fields[]>([getFieldsInitialValue()]);
    const [filterData, setFilterData] = useState<Filter[]>([]);
    const [daysBack, setDaysBack] = useState(userModule.daysToShow);

    // const [field, setField] = useState<Fields>(getFieldsInitialValue());
    const [fieldValue, setFieldValue] = useState("");
    const [field, setField] = useState(0);
    const [selectedField, setSelectedField] = useState<Fields>();

    const validationFilter = useFormik({
        enableReinitialize: true,

        initialValues: {
            field: "",
            value: "",
        },
        validationSchema: Yup.object({
            field: Yup.string()
                .required("Please choose your field"),
            value: Yup.string()
                .required("Please enter your value"),
        }),
        onSubmit: (values: any) => {
            setFieldValue(values.value);
            setField(values.field as number);
        }
    });

    useEffect(() => {
        if (fieldValue !== "" && field !== undefined) {
            let filter: Filter = {
                userModuleId: userModule.userModuleId as number,
                fieldId: field as number,
                value: fieldValue,
            }

            service.createFilterValue(filter)
                .then(function (response) {
                    setFilterData([
                        ...filterData,
                        (response.data.item as Filter)
                    ]);
                })
                .catch(function (error) {
                });
        }
    }, [field, fieldValue]);

    useEffect(() => {
        getFieldsByModuleId(userModule, setFieldsData);
        getFilter(userModule.userModuleId as number, setFilterData);
    }, [filter_center]);

    const updateDaysBack = () => {
        let asignModule: UserModules = {
            userId: userModule.userId,
            moduleId: userModule.moduleId as number,
            moduleName: userModule.moduleName,
            assigned: userModule.assigned,
            userModuleId: userModule.userModuleId,
            filterByExternalUserId: userModule.filterByExternalUserId,
            daysToShow: daysBack
        }
        service.updateDaysBack(asignModule)
            .then(function (response) {
                toast.success("Days to show updated successfully!");
            })
            .catch(function (error) {
            });
    }

    ///////////////////////////////////////
    const setFilterByExternalUserId = (check: boolean) => {
        let module: UserModules = {
            userId: userModule.userId,
            moduleId: userModule.moduleId as number,
            moduleName: userModule.moduleName,
            assigned: userModule.assigned,
            userModuleId: userModule.userModuleId,
            filterByExternalUserId: check,
            daysToShow: userModule.daysToShow
        }
        service.setFilterByExternalUserId(module)
            .then(function (response) {
                toast.success("Filter by StuffId updated successfully!");
            })
            .catch(function (error) {
            });
    }

    const onDeleteFilter = (value: Filter) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your field has been deleted.',
                    'success'
                )
                
                service.deleteFilterValue(value.userFilterFieldId as number)
                    .then(function (response) {
                        setFilterData([
                            ...filterData.filter((element) => {
                                return element.userFilterFieldId !== value.userFilterFieldId
                            }),
                        ]);
                    })
                    .catch(function (error) {
                    });
            }
        })
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Modal
                    // size="lg"
                    size="xl"
                    isOpen={filter_center}
                    toggle={() => {
                        tog_filterCenter();
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mx-auto"> {userModule.moduleName}</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setFilter_center(false);
                            }}
                            className="btn-close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                        </button>
                    </div>
                    
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validationFilter.handleSubmit();
                            return false;
                        }}
                    >
                        <Container fluid >
                            <div className="modal-body">
                                {
                                    location.state.externalUserId !== null ? 
                                    
                                    <><Row>
                                            <h6>Filter Records by Stuff Id</h6>
                                        </Row><Row className="mb-5">
                                                <Col className="col-4">
                                                    <Input
                                                        key={userModule.moduleId}
                                                        name="sortable"
                                                        type="checkbox"
                                                        onChange={(event: any) => {
                                                            setFilterByExternalUserId(event.target.checked);
                                                        } }
                                                        value="false"
                                                        defaultChecked={userModule.filterByExternalUserId} />
                                                </Col>
                                            </Row></>
                                    
                                    : 
                                     <></>
                                }
                                
                                <Row>
                                    <h6>Number of days back</h6> 
                                </Row>
                                <Row>
                                    <Col className="col-4">
                                        <Input
                                            id="daysBack"
                                            name="value"
                                            defaultValue={userModule.daysToShow}
                                            onChange={(e) => {setDaysBack(parseInt(e.target.value))}}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter number of days"
                                        />
                                    </Col>
                                    <Col className="col-3">
                                        <button type="button" onClick={updateDaysBack} className="btn btn-primary" style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}>
                                            Save
                                        </button>
                                    </Col> 
                                </Row>
                                <Row >
                                    <div style={{ paddingBottom: 20, paddingTop:30 }} >
                                        <div className="table-responsive p-10">
                                            <DataTable
                                                data={filterData}
                                                columns={UserModuleFilterFieldsColumns(onDeleteFilter)}
                                                customStyles={DefaultThemeForReactTable()}
                                            />
                                        </div>
                                    </div>
                                </Row>

                                <Row className="p-20">
                                    <Col className="col-4">
                                        <Input
                                            id="field"
                                            name="field"
                                            className="form-control"
                                            type="select"
                                            onChange={(e) => {
                                                validationFilter.handleChange(e);
                                                let item = fieldsData.find(i => i.fieldId === parseInt(e.target.value));
                                                setSelectedField(item);
                                            }}
                                            onBlur={validationFilter.handleBlur}
                                            value={validationFilter.values.field || ""}
                                            invalid={
                                                validationFilter.touched.field && validationFilter.errors.field ? true : false
                                            }>
                                            <option value="" hidden>Select</option>
                                            {fieldsData.map((field, i) => {
                                                return (
                                                    <option key={field.fieldId} value={field.fieldId} style={{ fontSize: "120%" }}>
                                                        {field.type === "date" ? field.name + " From" : field.name}
                                                    </option>
                                                );
                                            })}
                                            {validationFilter.touched.field && validationFilter.errors.field ? (
                                                <FormFeedback type="invalid"><>{validationFilter.errors.field}</></FormFeedback>
                                            ) : null}
                                        </Input>

                                    </Col>
                                    {
                                        selectedField?.type === "date" ?
                                        <Col className="col-7">
                                        <Input
                                            id="value"
                                            name="value"
                                            type="date"
                                            className="form-control"
                                            placeholder="Enter field value"
                                            onChange={validationFilter.handleChange}
                                            onBlur={validationFilter.handleBlur}
                                            value={validationFilter.values.value || ""}
                                            invalid={
                                                validationFilter.touched.value && validationFilter.errors.value ? true : false
                                            }
                                        />
                                        {validationFilter.touched.value && validationFilter.errors.value ? (
                                            <FormFeedback type="invalid"><>{validationFilter.errors.value}</></FormFeedback>
                                        ) : null}
                                    </Col> :
                                    <Col className="col-7">
                                        <Input
                                            id="value"
                                            name="value"
                                            className="form-control"
                                            placeholder="Enter field value"
                                            onChange={validationFilter.handleChange}
                                            onBlur={validationFilter.handleBlur}
                                            value={validationFilter.values.value || ""}
                                            invalid={
                                                validationFilter.touched.value && validationFilter.errors.value ? true : false
                                            }
                                        />
                                        {validationFilter.touched.value && validationFilter.errors.value ? (
                                            <FormFeedback type="invalid"><>{validationFilter.errors.value}</></FormFeedback>
                                        ) : null}
                                    </Col>
                                    }
                                    <Col className="col-1">
                                        <button type="submit" className="btn btn-primary" style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}>
                                            Save
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <br />
                    </Form>
                </Modal>
            </div>
        </React.Fragment>
    );
}
UserModuleFilterFields.propTypes = {

};

export default UserModuleFilterFields;

export const getFieldsByModuleId = (userModule: UserModules, setFieldsData: any) => {
    service.getFieldsByModuleId(userModule.moduleId as number)
        .then(function (response: any) {
            setFieldsData(response.data.data);
        })
        .catch(function (error: any) {
        })
}

export const getFilter = (userModuleId: number, setFilterData: any) => {
    service.getFilterValue(userModuleId)
        .then(function (response: any) {
            setFilterData(response.data.data);
        })
        .catch(function (error: any) {
        })
}