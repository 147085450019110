export interface Users {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    clientConfirmationEmailURI: string;
}

export const geUsersInitialValue =
    (): Users => {
        return {
            userId: "",
            firstName: "",
            lastName: "",
            email: "",
            role: "",
            clientConfirmationEmailURI: "",
        };
    };

    export interface ExecutedActions {
        actionId: number;
        moduleRecordId: number;
        editedJson: string;
    }

    export const geExecutedActionsInitialValue =
    (): ExecutedActions => {
        return {
            actionId: 0,
            moduleRecordId: 0,
            editedJson: "string"
        };
    };