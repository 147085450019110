import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Fields, Modules } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import * as Yup from "yup";

const service = new SuperAdminService();
export const EditFieldTab = () => {
    const location = useLocation();
    const history = useHistory();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            moduleId: location.state.fields.moduleId,
            fieldId: location.state.fields.fieldId,
            name: location.state.fields.name,
            orderNo: location.state.fields.orderNo,
            label: location.state.fields.label,
            type: location.state.fields.type,
            sortable: location.state.fields.sortable,
            showOnList: location.state.fields.showOnList,
            showOnForm: location.state.fields.showOnForm,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Please enter name"),
            label: Yup.string()
                .required("Please enter label"),
            orderNo: Yup.string()
                .required("Please enter orderNo"),
        }),
        onSubmit: (values: any) => {
            let field: Fields = {
                moduleId: values.moduleId,
                fieldId: values.fieldId,
                name: values.name,
                label: values.label,
                orderNo: values.orderNo,
                type: values.type,
                sortable: JSON.parse(values.sortable),
                showOnList: JSON.parse(values.showOnList),
                showOnForm: JSON.parse(values.showOnForm),
                isFilterField: location.state.fields.isFilterField
            }
            
            service.editFields(field)
                .then(function (response) {
                    history.push({
                        pathname: '/fields-module',
                        state: location.state.module as Modules
                    });
                    toast.success("Successfully saved!");
                })
                .catch(function (error) {
                });
        }
    });

    useEffect(() => {
        
    }, []);
    document.title = "Edit field | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid">
                
                <div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Container fluid>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Name:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10">
                                    <Input
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        placeholder="Enter module name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid"><>{validation.errors.name}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Label:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10">
                                    <Input
                                        id="label"
                                        name="label"
                                        className="form-control"
                                        placeholder="Enter module label"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.label || ""}
                                        invalid={
                                            validation.touched.label && validation.errors.label ? true : false
                                        }
                                    />
                                    {validation.touched.label && validation.errors.label ? (
                                        <FormFeedback type="invalid"><>{validation.errors.label}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>OrderNo:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10">
                                    <Input
                                        id="orderNo"
                                        name="orderNo"
                                        className="form-control"
                                        placeholder="Enter module OrederNo"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.orderNo || "0"}
                                        invalid={
                                            validation.touched.orderNo && validation.errors.orderNo ? true : false
                                        }
                                    />
                                    {validation.touched.orderNo && validation.errors.orderNo ? (
                                        <FormFeedback type="invalid"><>{validation.errors.orderNo}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Type:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10">
                                    <Input
                                        id="type"
                                        name="type"
                                        className="form-control"
                                        type="select"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.type || ""}
                                    >
                                        <option disabled>Choose a type</option>
                                        <option>string</option>
                                        <option>number</option>
                                        <option>boolean</option>
                                        <option>date</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Is sortable:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10 p-2">
                                    <FormGroup tag="fieldset" className="px-2">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    name="sortable"
                                                    type="checkbox"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value="false"
                                                    defaultChecked={location.state.fields.sortable === true ? true : false}
                                                />
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Show on list:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10 p-2">
                                    <FormGroup tag="fieldset" className="px-2">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    name="showOnList"
                                                    type="checkbox"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value="false"
                                                    defaultChecked={location.state.fields.showOnList === true ? true : false}
                                                />
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Show on form:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10 p-2">
                                    <FormGroup tag="fieldset" className="px-2">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    name="showOnForm"
                                                    type="checkbox"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value="false"
                                                    defaultChecked={location.state.fields.showOnForm === true ? true : false}
                                                />
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid>
                            <div className="d-flex flex-row-reverse">
                                <div className="p-1">
                                    <button
                                        className="btn btn-primary btn-block"
                                        style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="p-1">
                                    <Link
                                        className="btn btn-secondary btn-block"
                                        to={{
                                            pathname: '/fields-module',
                                            state: location.state.module as Modules
                                        }}
                                    >
                                        Cancel
                                    </Link>
                                </div>
                            </div>
                        </Container>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}


export default EditFieldTab;