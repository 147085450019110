import React, { useEffect, useState } from "react";
import { Col, Container, FormGroup, Input, Label, Modal } from "reactstrap";
import { UserActions, UserModules } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";

export interface UserModuleActionsProps {
    userModule: UserModules;
    action_center: boolean;
    setAction_center: (value: boolean) => void;
    tog_actionCenter: () => void;
}

const service = new SuperAdminService()
export const UserModuleActions: React.FC<UserModuleActionsProps> = ({
    userModule,
    action_center,
    setAction_center,
    tog_actionCenter,
}) => {
    const [userActionsData, setUserActionsData] = useState<UserActions[]>([]);

    const asignedUserActions = (actions: UserActions, check: boolean) => {
        let asignUserActions: UserActions = {
            actionId: actions.actionId,
            actionName: actions.actionName,
            userModuleId: userModule.userModuleId as number,
            assigned: check,
        }
        
        service.asignUserActions(asignUserActions)
            .then(function (response) {
                ////update state when assigned change
                const arr: UserActions[] = [];
                userActionsData.forEach((element, index) => {
                    if (element.userModuleId === userModule.moduleId) {
                        if (check === true) {
                            element.assigned = true;
                            arr.push(element)
                        } else {
                            element.assigned = false;
                            arr.push(element)
                        }
                    } else {
                        arr.push(element)
                    }
                });
                
                setUserActionsData(arr);
            })
            .catch(function (error) {
            });
    }
    useEffect(() => {
        getUserActions(userModule.userModuleId as number, userModule.moduleId as number, setUserActionsData)
    }, [action_center]);

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Modal
                    isOpen={action_center}
                    toggle={() => {
                        tog_actionCenter();
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mx-auto">{userModule.moduleName}</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setAction_center(false);
                            }}
                            className="btn-close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                        </button>
                    </div>

                    <Container fluid >
                        <div className="modal-body">
                            {userActionsData.map((actions) => {
                                return (
                                    <Col lg={4} md={5} className="col-xxl-11 " key={actions.actionId}>
                                        <FormGroup tag="fieldset" className="px-3">
                                            <FormGroup check>
                                                <Label check >
                                                    <Input
                                                        name={actions.actionName}
                                                        type="checkbox"
                                                        onChange={(event: any) => {
                                                            asignedUserActions(actions as UserActions, event.target.checked)
                                                        }}
                                                        value="false"
                                                        defaultChecked={actions.assigned}
                                                    />
                                                    {actions.actionName}
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                );
                            })}
                        </div>
                    </Container>
                </Modal>
            </div>
        </React.Fragment>
    );
}
UserModuleActions.propTypes = {

};

export default UserModuleActions;

export const getUserActions = (userModuleId: number, moduleId: number, setUserActionsData: any) => {
    service.getUserActions(userModuleId, moduleId)
        .then(function (response: any) {
            setUserActionsData(response.data.data);
        })
        .catch(function (error: any) {
        })
}