import { BaseRoutingContext } from "./BaseRoutingContext";
import { RoutingContext } from "./ReactRouter";

export function apiRoutesForContext(
    context: RoutingContext = new BaseRoutingContext(true),
) {
    return {
        server: {
            root: () => context.getApiBaseUrl()
        },
        auth: () => {
            return {
                login: () => "/Account/Token",
                register: () => "/Account/Registration",
                getEmailComfirmationUrl: () => "/Account/EmailConfirmation",
                forgotPassword: () => "/Account/ForgotPassword",
                resetPassword: () => "/Account/ResetPassword",
            };
        },
        profile: () => {
            return {
                changePassword: () => "/Account/ChangePassword",
                getUser: () => "/Users",
                updateUser: () => "/Users/update-by-current-user",

            };
        },
        superAdmin: () => {
            return {
                //Module
                createModule: () => "/Modules",
                getModules: () => "/Modules",
                editModule: () => "/Modules",
                deleteModule: () => "/Modules",
                //Field
                createField: () => "/Fields",
                getFields: () => "/Fields",
                editField: () => "/Fields",
                deleteField: () => "/Fields",
                mappingsField:()=>"/ApiMappings",
                getMappingsFieldbByFieldId:()=>"/ApiMappings/field",
                editMappingsField:()=>"/ApiMappings",
                //Actions
                createAction: () => "/Actions",
                getActions: () => "/Actions",
                deleteAction: () => "/Actions",
                editAction: () => "/Actions",
                //user_managment
                createUser: () => "/Users",
                getUsers: () => "/Users",
                editUser: () => "/Users",
                deleteUser: () => "/Users",
                asignModule: () => "/UserModules",
                getUserModules: () => "/UserModules",
                approveRegistrationUser: () => "/Users/approve-registration",
                //Filter
                createFilterValue: () => "/UserFilterFields",
                getFilterValue: () => "/UserFilterFields",
                deleteFilterValue: () => "/UserFilterFields",
                updateDaysBack: () => "/UserModules/days-back",
                setFilterByExternalUserId: () => "/UserModules/filter-by-externaluserid",
                //UserActions for user_management
                asignUserActions: () => "/UserActions",
                getUserActions: () => "/UserActions",
            };
        },
        user: () => {
            return {
                getModulesByUserId: () => "/UserModules/current-user",
                getModuleRecordsByUserModuleId: () => "/ModuleRecords",
                moduleRecordsByUserModuleId: () => "/ModuleRecords/new-get",
                getModuleRecordsByModuleRecordId: () => "/ModuleRecords",
                executedActions: () => "/ExecutedActions",
            };
        },
    };
}
