import React, { useEffect, useState } from "react";
//reactstrap
import { Col, Container, Row } from "reactstrap";

import { useHistory, useLocation, withRouter } from "react-router-dom";
import { EmailComfirmationURL, getEmailComfirmationURLInitialValues } from "src/sdk";
import { AuthService } from "src/services/AuthService";

const service = new AuthService();

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const RegisterSuccess = () => {
    const history = useHistory();
    let query = useQuery();
    const [code, setCode] = useState("");
    const [register, setRegister] = useState<boolean>(false);

    useEffect(() => {
        let userId = query.get("userId") as string;
        let code = query.get("code") as string;
        if (code !== null) {
            code = code.replaceAll("+", "%2B");
            code = code.replaceAll("/", "%2F");
            code = code.replaceAll("=", "%3D");

        } else {
            code = "0";
        }
        setCode(code);
        let emailComfirmationUrlData: EmailComfirmationURL = getEmailComfirmationURLInitialValues();
        emailComfirmationUrlData.userId = userId;
        emailComfirmationUrlData.code = code;
        service.getEmailComfirmationURL(emailComfirmationUrlData)
            .then(function (response) {
                setRegister(true);
            })
            .catch(function (error) {
                setRegister(false);
            })
    }, []);

    document.title = "Register success| Synth";
    return (
        <React.Fragment>
            <div className="auth-page">
                {register === true
                    ?
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col lg={4} md={5} className="col-xxl-4">
                                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100">
                                            <div className="auth-content my-auto">
                                                <div className="text-center">
                                                    <h5 className="mb-0">Whoo Whoo!</h5>
                                                    <p className="text-muted mt-2">
                                                        You have successfully registered!
                                                    </p>
                                                    <p className="text-muted mt-2">
                                                        You can log in now!
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        type="submit"
                                                        onClick={() => {
                                                            history.push("/login");
                                                        }}
                                                    >
                                                        Login
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container> :
                    <Container fluid className="p-0">
                        <Row className="justify-content-center">
                            <Col lg={4} md={5} className="col-xxl-4">
                                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100">
                                            <div className="auth-content my-auto">
                                                <div className="text-center p-5">
                                                    <h5 className="mb-0">You need to register first!</h5>
                                                    <p className="text-muted mt-2">
                                                        Then check your email!!
                                                    </p>

                                                </div>
                                                <div className="mb-3 ">
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        type="submit"
                                                        onClick={() => {
                                                            history.push("/register");
                                                        }}
                                                    >
                                                        Sign up
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>}

            </div>
        </React.Fragment>
    );
};

export default withRouter(RegisterSuccess);