import { useFormik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { Actions, ExecutedActions, FieldValue } from "src/sdk";
import { UserService } from "src/services/UserService";
import moment from 'moment';
import * as Yup from "yup";
import { FilterInput } from "./DynamicModules";



export interface ModuleActionPopupProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    action: Actions;
    properties: FilterInput[];
}

const service = new UserService();
export const ModuleActionPopup: React.FC<ModuleActionPopupProps> = ({
    openModal,
    action,
    setOpenModal,
    properties
}) => {

    const [actionUsername, setActionUsername] = useState("");

    const [actionPassword, setActionPassword] = useState("");

    const [selectedField, setSelectedField] = useState("Custom");

    const [optionalParam, setOptionalParam] = useState<boolean>(true);

    const radioButtonChange = (value: any) => {
        setOptionalParam(value);
    }

    const form = useFormik({
        enableReinitialize: true,

        initialValues: {
            field: "",
            value: "",
        },
        validationSchema: Yup.object({
            field: Yup.string()
                .required("Please choose your field"),
            value: Yup.string()
                .required("Please enter your value"),
        }),
        onSubmit: (values: any) => {
            let username = action.username;
            let password = action.password;

            let base64encodedData = Buffer.from(username + ':' + password).toString('base64');
            /////////////////////////////
            service.filemakerAuthentication(action, base64encodedData)
                .then(function (fmresponse) {
                    let scriptParam = {
                        fieldName: values.field,
                        value: values.value
                    };
                    console.log(scriptParam);
                    service.getActionLinkByPublicId(action, JSON.stringify(scriptParam), fmresponse.data.response.token)
                        .then(function (response2) {
                            service.closeFilemakerSession(action, fmresponse.data.response.token, base64encodedData)
                                .then(function (fmresponse2) {
                                    toast.success(`Message: ${JSON.parse(response2.data.response.scriptResult).message}  Status: ${JSON.parse(response2.data.response.scriptResult).status}`);
                                })
                                .catch(function (error) {
                                })
                        })
                        .catch(function (error) {
                        })
                })
                .catch(function (error) {
                })

                setOpenModal(false);
        }
    });

    const form1 = useFormik({
        enableReinitialize: true,

        initialValues: {
            value: action.scriptParam,
        },
        validationSchema: Yup.object({
            value: Yup.string()
                .required("Please enter your value"),
        }),
        onSubmit: (values: any) => {
            let username = action.username;
            let password = action.password;

            let base64encodedData = Buffer.from(username + ':' + password).toString('base64');
            /////////////////////////////
            service.filemakerAuthentication(action, base64encodedData)
                .then(function (fmresponse) {
                    let scriptParam = {
                        fieldName: "Custom",
                        value: values.value
                    };
                    console.log(scriptParam);
                    service.getActionLinkByPublicId(action, JSON.stringify(scriptParam), fmresponse.data.response.token)
                        .then(function (response2) {
                            service.closeFilemakerSession(action, fmresponse.data.response.token, base64encodedData)
                                .then(function (fmresponse2) {
                                    toast.success(`Message: ${JSON.parse(response2.data.response.scriptResult).message}  Status: ${JSON.parse(response2.data.response.scriptResult).status}`);
                                })
                                .catch(function (error) {
                                })
                        })
                        .catch(function (error) {
                        })
                })
                .catch(function (error) {
                })
            
                setOpenModal(false);
        }
    });

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Modal
                    isOpen={openModal}
                    scrollable={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Select action param:</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                                setOptionalParam(true);
                                form.resetForm();
                                form1.resetForm();
                            }}
                            className="btn-close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col>
                                <label>
                                    <input
                                        id="deletedDate"
                                        data-cy="deletedDate"
                                        type="radio"
                                        name="deletedDate"
                                        value="true"
                                        checked={optionalParam === true}
                                        onChange={() => radioButtonChange(true)}
                                    />{" "}
                                    Optional Param
                                </label>
                            </Col>
                            <Col>
                                <label>
                                    <input
                                        id="deletedDate"
                                        data-cy="deletedDate"
                                        type="radio"
                                        name="deletedDate"
                                        value="false"
                                        checked={optionalParam === false}
                                        onChange={() => radioButtonChange(false)}
                                    />{" "}
                                    Field Param
                                </label>
                            </Col>
                        </Row>
                        {
                            optionalParam ?
                                <Form style={{ marginTop: 10 }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        form1.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row className="p-20">
                                        <Col className="col-6">
                                            <label>Enter param value:</label>
                                        </Col>
                                        <Col className="col-6">
                                            <Input
                                                id="value"
                                                name="value"
                                                className="form-control"
                                                placeholder="Enter field value"
                                                onChange={form1.handleChange}
                                                onBlur={form1.handleBlur}
                                                value={form1.values.value || ""}
                                                invalid={
                                                    form1.touched.value && form1.errors.value ? true : false
                                                }
                                            />
                                            {form1.touched.value && form1.errors.value ? (
                                                <FormFeedback type="invalid"><>{form1.errors.value}</></FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Row>
                                            <Col className="col-12">
                                                <button type="submit" className="btn btn-primary" style={{ marginTop: 20, border: 0, backgroundColor: "#95CBB2", color: "#FFFFFF" }}>
                                                    Execute action
                                                </button>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <br />
                                </Form> :
                                <Form style={{ marginTop: 10 }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        form.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row className="p-20">
                                        <Col className="col-6">
                                            <Input
                                                id="field"
                                                name="field"
                                                className="form-control"
                                                type="select"
                                                onChange={(e) => {
                                                    form.handleChange(e);
                                                    setSelectedField(e.target.value);
                                                }}
                                                onBlur={form.handleBlur}
                                                value={form.values.field || ""}
                                                invalid={
                                                    form.touched.field && form.errors.field ? true : false
                                                }>
                                                <option value="" hidden>Select</option>
                                                {properties.map((element: any, index: any) => {
                                                    return (
                                                        <option key={element.FieldName} value={element.FieldName} style={{ fontSize: "120%" }}>
                                                            {element.FieldName}
                                                        </option>
                                                    );
                                                })}

                                                {form.touched.field && form.errors.field ? (
                                                    <FormFeedback type="invalid"><>{form.errors.field}</></FormFeedback>
                                                ) : null}
                                            </Input>

                                        </Col>
                                        <Col className="col-6">
                                            <Input
                                                id="value"
                                                name="value"
                                                className="form-control"
                                                placeholder="Enter field value"
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.value || ""}
                                                invalid={
                                                    form.touched.value && form.errors.value ? true : false
                                                }
                                            />
                                            {form.touched.value && form.errors.value ? (
                                                <FormFeedback type="invalid"><>{form.errors.value}</></FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Row>
                                            <Col className="col-12">
                                                <button type="submit" className="btn btn-primary" style={{ marginTop: 20, border: 0, backgroundColor: "#95CBB2", color: "#FFFFFF" }}>
                                                    Execute action
                                                </button>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <br />
                                </Form>
                        }
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    );
}

ModuleActionPopup.propTypes = {

};
export default withTranslation()(ModuleActionPopup);
