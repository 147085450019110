import React, { useEffect, useState } from "react";
import DataTable, { SortOrder } from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import { Loader } from "src/components";
import { DefaultThemeForReactTable } from "src/components/Table";
import { Fields, getPaginationRequestInitialValue, Modules, PaginationRequest } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import Swal from 'sweetalert2';
import { ModduleFieldsColumns } from "./Components/ModuleFieldsColumns";
import { Link } from "react-router-dom";

const service = new SuperAdminService();
export const ModuleFields = () => {
  const location = useLocation();
  const history = useHistory();

  const [fieldsData, setFieldsData] = useState<Fields[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false);
  const [totalRows, setTotalRows] = useState(10000);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  ////////////////////////Pagination
  const fetchPage = async (page: any, sortBy: any, sortOrder: any) => {
    setLoading(true);
    let pagination: PaginationRequest = getPaginationRequestInitialValue();
    pagination.moduleId = location.state.moduleId;
    pagination.page = page;
    pagination.pageSize = perPage;
    pagination.sortBy = sortBy;
    pagination.sortOrder = sortOrder;

    getFieldsByModuleIdWithPagination(setFieldsData, pagination, setTotalRows, setLoading);
    setPaginationResetDefaultPage(false);
  };

  useEffect(() => {
    fetchPage(page, sortBy, sortOrder); // fetch page 1 of users
  }, []);

  const handlePageChange = (pageNew: any) => {
    fetchPage(pageNew, sortBy, sortOrder);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setLoading(true);
    let pagination: PaginationRequest = getPaginationRequestInitialValue();
    pagination.page = page;
    pagination.pageSize = newPerPage;
    pagination.sortBy = sortBy;
    pagination.sortOrder = sortOrder;

    getFieldsByModuleIdWithPagination(setFieldsData, pagination, setTotalRows, setLoading);
    setPerPage(newPerPage);
  };

  const sortPagination = (state: any, sortDirection: SortOrder) => {
    setSortOrder(sortDirection);
    setSortBy(state.id);
    fetchPage(page, state.id, sortDirection); // fetch page 1 of users
  }

  /////////////////////// Style Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page",
    rangeSeparatorText: "of",
    selectAllRowsItem: false,
  };

  const onCreateField = () => {
    history.push({
      pathname: '/create-field',
      state: location.state as Modules
    })
  }

  const onEditField = (values: Fields) => {
    history.push({
      pathname: '/edit-field',
      state: { fields: values, module: location.state }
    })
  }

  const onDeleteField = (fieldId: number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your field has been deleted.',
          'success'
        )
        service.deleteField(fieldId)
          .then(function (response) {
            setFieldsData([
              ...fieldsData.filter((element) => {
                return element.fieldId !== fieldId
              }),
            ]);
          })
          .catch(function (error) {
          });
      }
    })
  }
  useEffect(() => {

  }, [fieldsData]);

  document.title = "Fields | Synth";
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
              <Link style={{color:"black"}}
                  to="/all-modules"
                  className="nav_link"
                >
                  <h5>Modules</h5>
                </Link>
              <i className="bi bi-chevron-right"></i>
              <h5 style={{marginRight:13, marginLeft:13}}>{location.state.name} Module</h5>
              <i className="bi bi-chevron-right"></i>
              <h5 style={{marginLeft:13, opacity:0.6}}>Fields List</h5>
            </div>
          </div>
        </div>
        <div className="mb-2 d-flex flex-row-reverse">
          <button
            className="btn btn-secondary btn-block"
            onClick={onCreateField}
          >
            Add field
          </button>
        </div>
        <div style={{ width: "100%" }} >
          <div className="table-responsive">
            <DataTable
              data={fieldsData}
              columns={ModduleFieldsColumns(onEditField, onDeleteField)}
              customStyles={DefaultThemeForReactTable()}
              progressPending={loading}
              progressComponent={<Loader loading={loading} />}
              ///////////////////////////
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationResetDefaultPage={paginationResetDefaultPage}
              onChangeRowsPerPage={handlePerRowsChange}
              pointerOnHover={true}
              onChangePage={handlePageChange}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              onSort={sortPagination}
              defaultSortAsc={false}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

ModuleFields.propTypes = {

};

export default ModuleFields;

export const getFieldsByModuleIdWithPagination = (setFieldsData: any, pagination: any, setTotalRows: any, setLoading: any) => {
  service.getFieldsByModuleIdWithPagination(pagination)
    .then(function (response) {
      setTotalRows(response.data.totalRecords);
      setFieldsData(response.data.data);
      setLoading(false);
    })
    .catch(function (error) {
    })
} 