import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import React from "react";
import { useHistory, useLocation } from "react-router";
import { Col, Container, Form, FormFeedback, Input, Row } from "reactstrap";
import { CookiesKey, getCookie } from "src/globals";
import { User } from "src/sdk";
import { geUsersInitialValue, Users } from "src/sdk/models/Users";
import { ProfileService } from "src/services/ProfileService";
import { toast } from "react-toastify";

const service = new ProfileService();
export const EditBasicInfo = () => {

    const [profile, setProfile] = useState<Users>(geUsersInitialValue);

    const userJsonValues = getCookie(CookiesKey.USER_COOKIE);
    useEffect(() => {
        if (userJsonValues) {
            const user = JSON.parse(userJsonValues) as User;
            if (user?.id !== undefined) {
                service.getUserById(user?.id)
                    .then(function (response) {
                        setProfile(response.data.item);
                    })
                    .catch(function (error) {
                    })
            }
        }
    }, [userJsonValues]);

    ////////////////////////////
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            firstName: profile.firstName,
            lastName: profile.lastName,
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required("Please enter first name"),
            lastName: Yup.string()
                .required("Please enter last name"),
        }),
        onSubmit: (values: any) => {
            const newProfile: Users = {
                userId: profile.userId,
                firstName: values.firstName,
                lastName: values.lastName,
                email: profile.email,
                role: profile.role,
                clientConfirmationEmailURI: profile.clientConfirmationEmailURI,
            }

            service.updateUser(values)
                .then(function (response) {
                    toast.success("Successfully updated!");
                })
                .catch(function (error) {
                })
        }
    });

    document.title = "Edit user | Synth";
    return <React.Fragment>
        <Row>
            <Col>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Container fluid>
                        <div className="text-center mb-2 p-5">
                            <h3>Edit user</h3>
                        </div>
                        <Row>
                            <Col className="col-3 p-2">
                                <h5>First name:</h5>
                            </Col>
                            <Col>
                                <Input
                                    id="firstName"
                                    name="firstName"
                                    className="form-control"
                                    placeholder="Enter first name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName || ""}
                                    invalid={
                                        validation.touched.firstName && validation.errors.firstName ? true : false
                                    }
                                />
                                {validation.touched.firstName && validation.errors.firstName ? (
                                    <FormFeedback type="invalid"><>{validation.errors.firstName}</></FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row >
                            <Col className="col-3 p-2">
                                <h5>Last name:</h5>
                            </Col>
                            <Col>
                                <Input
                                    id="lastName"
                                    name="lastName"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName || ""}
                                    invalid={
                                        validation.touched.lastName && validation.errors.lastName ? true : false
                                    }
                                />
                                {validation.touched.lastName && validation.errors.lastName ? (
                                    <FormFeedback type="invalid"><>{validation.errors.lastName}</></FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <div className="d-flex flex-row-reverse">
                                <div className="p-1">
                                    <button
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Form>
            </Col>
        </Row>
    </React.Fragment>
}
export default EditBasicInfo;