import React, { useState } from "react";
//reactstrap
import { Alert, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import { Link, withRouter } from "react-router-dom";

// Formik Validation
import { useFormik } from "formik";
import * as Yup from "yup";

//service
import { AuthService } from "src/services/AuthService";
import logo from "../../assets/images/logo.png";



const service = new AuthService();
const ForgotPassword = () => {
  const [success, setSuccess] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      clientResetPassURI: "http://webapp.synthesishealthsoftware.com/resetPassword",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      service.forgotPassword(values)
        .then(function (response) {
          setSuccess("Check your mail!");
        })
        .catch(function (error) {
        })

    }
  });

  document.title = "Forgot Password | Synth";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4 mt-5" style={{ backgroundColor: "#274755", color: "white" }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <img src={logo} />
                      </div>
                      <hr className="my-4"/>
                      <div className="text-center">
                        <h5 className="mb-0">Forgot your password?</h5>
                      </div>
                      <Form
                        className="custom-form mt-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {success ? <Alert color="success">{success}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}

                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-light w-100 waves-effect waves-light"
                              type="submit"
                            >
                              Continue
                            </button>
                          </Col>
                        </Row>
                      </Form>
                      <div className="mt-5 text-center">
                        <p className="text-body-secondary mb-0">
                          Remember It ?{" "}
                          <Link
                            to="/login"
                            className="text-light fw-semibold"
                          >
                            {" "}
                            Sign In{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgotPassword);