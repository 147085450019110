
import PropTypes from 'prop-types';
import { useState } from 'react';
import AppHeader from './Header/AppHeader';
import AdminSidebar from './Sidebar/AdminSidebar';
import './Style/index.css';

interface LayoutProps {
  children: any;
}

export const AdminLayout = (props: LayoutProps) => {
  const [showNav, setShowNav] = useState(true)

  return (
    <>
      <div className={`body-area${showNav ? ' body-pd' : ''}`} >
        <AdminSidebar showNav={showNav} />
        <AppHeader showNav={showNav} setShowNav={setShowNav} />
        <div className="container-fluid">
          {props.children}
        </div>
      </div>
    </>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default AdminLayout;
