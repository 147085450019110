import React, { useState } from "react";
import {
    Dropdown, DropdownMenu, DropdownToggle
} from "reactstrap";

import FontAwesome from 'react-fontawesome';
import { Users } from "src/sdk";


export interface DropdownComponentProps {
    values?: Users;
    onEditUser?: (values: Users) => void;
    userId?: string;
    onDeleteUser?: (userId: string) => void;
}

const DropdownComponent: React.FC<DropdownComponentProps> = ({
    values,
    onEditUser,
    userId,
    onDeleteUser,
}) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false);
    const [name, setName] = useState("Options");

    const editUser = () => {
        if (onEditUser) onEditUser(values as Users);
    };

    const deleteUser = () => {
        if (onDeleteUser) onDeleteUser(userId as string);
    };
    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item bg-soft-light"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <FontAwesome name="caret-down" />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
                </DropdownToggle>
                <DropdownMenu
                    className="dropdown-menu-end"
                    style={{ width: "150px" }}
                >
                    <div className="dropdown-item" onClick={editUser}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Edit</span>
                    </div>
                    <div className="dropdown-item" onClick={deleteUser}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Delete</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

DropdownComponent.propTypes = {

};
export default DropdownComponent;
