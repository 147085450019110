import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Dropdown, DropdownMenu, DropdownToggle
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// users
import user1 from "src/assets/images/users/avatar-1.jpg";
import { useUserContext } from "src/globals/providers";
import '../Style/index.css';


const ProfileMenu = (props: any) => {
  const { user, setUser } = useUserContext();
  const [menu, setMenu] = useState<boolean>(false);

  return (
    <React.Fragment>

      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light "
          style={{ position: "static" }}
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
            height="30"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1 text-light">{user?.username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu
          style={{ width: "150px" }}
          end
          data-bs-display="static"
        >
          {
            user?.role === "SuperAdministrator"
              ?
              <Link to="/admin-edit-profile" className="dropdown-item">
                <i className="bx bx-user font-size-16 align-middle me-1" />
                <span>Profile</span>
              </Link>
              :
              <Link to="/user-edit-profile" className="dropdown-item">
                <i className="bx bx-user font-size-16 align-middle me-1" />
                <span>Profile</span>
              </Link>
          }
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(ProfileMenu);
