import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { Modules } from "src/sdk";
import DropdownComponent from "./DropdownComponent";

export const ModulesColumns = memoizeOne(
    (
        fieldsModule: (values: Modules) => void,
        actionsModule: (Values: Modules) => void,
        onEditModule: (values: Modules) => void,
        onDeleteModule: (id: number) => void,
    ): TableColumn<Modules>[] => {
        return [
            {
                id: "moduleId",
                selector: (row: any) => row.moduleId,
                sortable: true,
                name: "Module id",
                width: "20%",
            },
            {
                id: "name",
                selector: (row: any) => row.name,
                sortable: true,
                name: "Name",
            },
            {
                cell: (row: any) => {
                    return (
                        <div className="m-auto">
                            <DropdownComponent
                                values={row as Modules}
                                id={row.moduleId as number}
                                fieldsModule={fieldsModule}
                                actionsModule={actionsModule}
                                onEditModule={onEditModule}
                                onDeleteModule={onDeleteModule}
                            />
                        </div>
                    );
                },
                width: "150px",
            },
        ];
    },
);