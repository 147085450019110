import classnames from "classnames";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { SuperAdminService } from "src/services/SuperAdminService";
import * as Yup from "yup";
import UserModules from "../UserModules/UserModules";

const service = new SuperAdminService();
export const EditUser = () => {
    const location = useLocation();
    const history = useHistory();
    ////////////////////////////
    const [activeTab, setactiveTab] = useState("1");
    const [headerValue, setHeaderValue] = useState("Edit");

    const toggle = (tab: any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    /////////////////////////////

    useEffect(() => {
        activeTab === "1" ? setHeaderValue("Basic Info") : setHeaderValue("Module Management");
    }, [activeTab]);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            userId: location.state.userId,
            firstName: location.state.firstName,
            lastName: location.state.lastName,
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required("Please enter first name"),
            lastName: Yup.string()
                .required("Please enter last name"),
        }),
        onSubmit: (values: any) => {
            service.editUser(values)
                .then(function (response) {
                    history.push("/users-managment");
                    toast.success("Successfully saved!");
                })
                .catch(function (error) {
                });
        }
    });
    useEffect(() => {
        
    }, []);
    document.title = "Edit user | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
                        <Link style={{color:"black"}}
                                to="/users-managment"
                                className="nav_link"
                                >
                                <h5>Users management</h5>
                        </Link>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginRight:13, marginLeft:13}}>{location.state.firstName} {location.state.lastName}</h5>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginLeft:13, opacity:0.6}}>{headerValue}</h5>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col xl={12}>
                        <Card style={{ border: 0 }}>
                            <CardBody >
                                <div className="row">
                                    <div className="col-2">
                                        <Nav tabs className="flex-column nav nav-pills">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer", backgroundColor: activeTab === "2" ? "white" : "#6c757d", color: activeTab === "2" ? "black" : "white" }}
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggle("1");
                                                    }}
                                                >
                                                    Basic info
                                                </NavLink>
                                            </NavItem>
                                            {location.state.role === "SuperAdministrator"
                                                ? null :
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer", backgroundColor: activeTab === "1" ? "white" : "#6c757d", color: activeTab === "1" ? "black" : "white" }}

                                                        className={classnames({
                                                            active: activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggle("2");
                                                        }}
                                                    >
                                                        Modules settings
                                                    </NavLink>
                                                </NavItem>
                                            }
                                        </Nav>
                                    </div>
                                    <div className="col-10">
                                        <TabContent activeTab={activeTab} className="text-muted ">
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12">
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                validation.handleSubmit();
                                                                return false;
                                                            }}
                                                        >
                                                            <Container fluid>
                                                                <Row >
                                                                    <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                                        <h5>First name:</h5>
                                                                    </Col>
                                                                    <Col lg={4} md={5} className="col-xxl-10">
                                                                        <Input
                                                                            id="firstName"
                                                                            name="firstName"
                                                                            className="form-control"
                                                                            placeholder="Enter first name"
                                                                            type="text"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation.values.firstName || ""}
                                                                            invalid={
                                                                                validation.touched.firstName && validation.errors.firstName ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.firstName && validation.errors.firstName ? (
                                                                            <FormFeedback type="invalid"><>{validation.errors.firstName}</></FormFeedback>
                                                                        ) : null}
                                                                    </Col>
                                                                </Row>
                                                                <Row >
                                                                    <Col lg={4} md={5} className="col-xxl-2 p-2">
                                                                        <h5>Last name:</h5>
                                                                    </Col>
                                                                    <Col lg={4} md={5} className="col-xxl-10">
                                                                        <Input
                                                                            id="lastName"
                                                                            name="lastName"
                                                                            className="form-control"
                                                                            placeholder="Enter last name"
                                                                            type="text"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation.values.lastName || ""}
                                                                            invalid={
                                                                                validation.touched.lastName && validation.errors.lastName ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.lastName && validation.errors.lastName ? (
                                                                            <FormFeedback type="invalid"><>{validation.errors.lastName}</></FormFeedback>
                                                                        ) : null}
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                            <Container fluid>
                                                                <div className="d-flex flex-row-reverse">
                                                                    <div className="p-1">
                                                                        <button
                                                                            className="btn btn-primary btn-block"
                                                                            type="submit"
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                    <div className="p-1">
                                                                        <Link
                                                                            className="btn btn-secondary btn-block"
                                                                            to="/users-managment"
                                                                        >
                                                                            Cancel
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </Container>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12">
                                                        <UserModules userId={location.state.userId} />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

EditUser.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(EditUser);