import React, { useState } from "react";
import FontAwesome from 'react-fontawesome';
import {
    Dropdown, DropdownMenu, DropdownToggle
} from "reactstrap";
import { Modules } from "src/sdk";

export interface DropdownComponentProps {
    values?: Modules;
    fieldsModule?: (values: Modules) => void;
    actionsModule?: (values: Modules) => void;
    onEditModule?: (values: Modules) => void;
    id?: number;
    onDeleteModule?: (id: number) => void;
}

const DropdownComponent: React.FC<DropdownComponentProps> = ({
    values,
    fieldsModule,
    actionsModule,
    onEditModule,
    id,
    onDeleteModule,
}) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false);
    const [name, setName] = useState("Options");

    const fields = () => {
        if (fieldsModule) fieldsModule(values as Modules);
    };

    const actions = () => {
        if (actionsModule) actionsModule(values as Modules);
    };

    const editModule = () => {
        if (onEditModule) onEditModule(values as Modules);
    };

    const deleteModule = () => {
        if (onDeleteModule) onDeleteModule(id as number);
    };
    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item bg-soft-light"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <FontAwesome name="caret-down" />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
                </DropdownToggle>
                <DropdownMenu 
                className="dropdown-menu-end"
                style={{ width: "150px" }}
                >
                    <div className="dropdown-item" onClick={fields}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Fields</span>
                    </div>
                    <div className="dropdown-item" onClick={actions}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Actions</span>
                    </div>
                    <div className="dropdown-item" onClick={editModule}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Edit</span>
                    </div>
                    <div className="dropdown-item" onClick={deleteModule}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Delete</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default DropdownComponent;
