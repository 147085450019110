import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DataTable, { SortOrder } from "react-data-table-component";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Form, Input } from "reactstrap";
import { DefaultThemeForReactTable, Loader } from "src/components";
import { getPaginationRequestInitialValue, Modules, PaginationRequest } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import Swal from 'sweetalert2';
import { ModulesColumns } from "./ModulesComponents/ModulesColumns";


const service = new SuperAdminService();
export const ModulesTable = () => {
  const history = useHistory();

  const [modulesData, setModulesData] = useState<Modules[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false);
  const [totalRows, setTotalRows] = useState(10000);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");

  /////////////////////////////search
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      search: ""
    },
    onSubmit: (values: any) => {
      fetchPage(page, values.search, true, sortBy, sortOrder);
      setSearch(values.search);
    }
  });
  ////////////////////////Pagination
  const fetchPage = async (page: any, search: any, paginationResetDefaultPage: any, sortBy: any, sortOrder: any) => {
    setLoading(true);

    let pagination: PaginationRequest = getPaginationRequestInitialValue();
    pagination.page = page;
    pagination.pageSize = perPage;
    pagination.search = search;
    pagination.sortBy = sortBy;
    pagination.sortOrder = sortOrder;

    getModules(setModulesData, pagination, setTotalRows, setLoading);
    setPaginationResetDefaultPage(paginationResetDefaultPage);
  };

  useEffect(() => {
    fetchPage(page, "", false, sortBy, sortOrder); // fetch page 1 of users
  }, []);

  const handlePageChange = (pageNew: any) => {
    fetchPage(pageNew, search, false, sortBy, sortOrder);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setLoading(true);
    let pagination: PaginationRequest = getPaginationRequestInitialValue();
    pagination.page = page;
    pagination.pageSize = newPerPage;
    pagination.search = search;
    pagination.sortBy = sortBy;
    pagination.sortOrder = sortOrder;

    getModules(setModulesData, pagination, setTotalRows, setLoading);
    setPerPage(newPerPage);
  };

  const sortPagination = (state: any, sortDirection: SortOrder) => {
    setSortOrder(sortDirection);
    setSortBy(state.id);
    fetchPage(page, search, false, state.id, sortDirection); // fetch page 1 of users
  }

  /////////////////////// Style Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page",
    rangeSeparatorText: "of",
    selectAllRowsItem: false,
  };
  ///////////////////////////////////////////////////////////////////

  const fieldsModule = (values: Modules) => {
    history.push({
      pathname: '/fields-module',
      state: values as Modules
    })
  }

  const actionsModule = (values: Modules) => {
    history.push({
      pathname: '/actions-module',
      state: values as Modules
    })
  }

  const onEditModule = (values: Modules) => {
    history.push({
      pathname: '/edit-module',
      state: values as Modules
    })
  }

  const onDeleteModule = (moduleId: number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your module has been deleted.',
          'success'
        )
        service.deleteModule(moduleId)
          .then(function (response) {
            setModulesData([
              ...modulesData.filter((element) => {
                return element.moduleId !== moduleId
              }),
            ]);
          })
          .catch(function (error) {
          });
      }
    })
  }
  //////////////////////////////////////

  document.title = "Modules | Synth";
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
              <Link style={{color:"black"}}
                  to="/all-modules"
                  className="nav_link"
                >
                  <h5>Modules</h5>
                </Link>
              <i className="bi bi-chevron-right"></i>
              <h5 style={{marginLeft:13, opacity:0.6}}>Modules List</h5>
            </div>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////// */}
        <div className="d-flex mb-3">
          <div className="me-auto w-50">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="form-group m-0">
                <div className="input-group">
                  <Input
                    name="search"
                    type="text"
                    className="form-control"
                    placeholder="Search by module name ..."
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.search || ""}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary rounded-0 rounded-end bi bi-search"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div>
            <Link
              className="btn btn-secondary btn-block"
              to="/create-module"         >
              Add module
            </Link>
          </div>
        </div>
        {/* ////////////////////////////////////////////////// */}

        <div style={{ width: "100%" }} >
          <div className="table-responsive">
            <DataTable
              data={modulesData}
              columns={ModulesColumns(fieldsModule, actionsModule, onEditModule, onDeleteModule)}
              customStyles={DefaultThemeForReactTable()}
              progressPending={loading}
              progressComponent={<Loader loading={loading} />}
              ///////////////Pagination
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationResetDefaultPage={paginationResetDefaultPage}
              onChangeRowsPerPage={handlePerRowsChange}
              pointerOnHover={true}
              onChangePage={handlePageChange}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              onSort={sortPagination}
              defaultSortAsc={false}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
ModulesTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(ModulesTable);

////////Get all modules
export const getModules = (setModulesData: any, pagination: any, setTotalRows: any, setLoading: any) => {
  service.getModules(pagination)
    .then(function (response) {
      setTotalRows(response.data.totalRecords);
      setModulesData(response.data.data);
      setLoading(false);
    })
    .catch(function (error) {
    })
} 