import { useFormik } from "formik";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Container, Row, Col, Input, FormFeedback } from "reactstrap";
import { FieldMappings, Modules } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import * as Yup from "yup";

export interface SourceFieldTabProps {
    mappingsField: FieldMappings;

}

const service = new SuperAdminService();
export const SourceFieldTab: React.FC<SourceFieldTabProps> = ({
    mappingsField
}) => {
    const location = useLocation();
    const history = useHistory();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            apiFieldName: mappingsField.apiFieldName,
        },
        validationSchema: Yup.object({
            apiFieldName: Yup.string()
                .required("Please enter source field name"),

        }),
        onSubmit: (values: any) => {
            if (mappingsField.apiFieldName === "") {
                let mapField: FieldMappings = {
                    fieldId: location.state.fields.fieldId,
                    originalFieldName: location.state.fields.label,
                    apiFieldName: values.apiFieldName,
                }
                
                service.mappingsField(mapField)
                    .then(function (response) {
                        toast.success("Successfully saved!");
                    })
                    .catch(function (error) {
                    });
            } else {
                let mapField: FieldMappings = {
                    apiMappingId: mappingsField.apiMappingId,
                    fieldId: mappingsField.fieldId,
                    originalFieldName: mappingsField.originalFieldName,
                    apiFieldName: values.apiFieldName,
                }

                service.editMappingsField(mapField)
                    .then(function (response) {
                        toast.success("Successfully saved!");
                    })
                    .catch(function (error) {
                    });
            }
        }
    });

    return (
        <React.Fragment>
            <div className="container-fluid">
                
                <div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Container fluid>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Source field name:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10">
                                    <Input
                                        id="apiFieldName"
                                        name="apiFieldName"
                                        className="form-control"
                                        placeholder="Enter field name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.apiFieldName || ""}
                                        invalid={
                                            validation.touched.apiFieldName && validation.errors.apiFieldName ? true : false
                                        }
                                    />
                                    {validation.touched.apiFieldName && validation.errors.apiFieldName ? (
                                        <FormFeedback type="invalid"><>{validation.errors.apiFieldName}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid>
                            <div className="d-flex flex-row-reverse">
                                <div className="p-1">
                                    <button
                                        className="btn btn-primary btn-block"
                                        style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                        type="submit"
                                    >
                                        Map
                                    </button>
                                </div>
                                <div className="p-1">
                                    <Link
                                        className="btn btn-secondary btn-block"
                                        to={{
                                            pathname: '/fields-module',
                                            state: location.state.module as Modules
                                        }}
                                    >
                                        Cancel
                                    </Link>
                                </div>
                            </div>
                        </Container>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}


export default SourceFieldTab;