import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { Filter } from "src/sdk";

export const UserModuleFilterFieldsColumns = memoizeOne(
    (
        onDelete: (filter: Filter) => void,
    ): TableColumn<Filter>[] => {
        return [
            {
                selector: (row: any) => row.fieldName,
                sortable: true,
                name: "Field name",
            },
            {
                selector: (row: any) => row.value,
                sortable: true,
                name: "Value",
            },
            {
                sortable: false,
                cell: (params, index, column, row) => {
                    return <>
                        <div>
                            <button
                                className="btn btn-secondary btn-block"
                                type="button"
                                onClick={() => { onDelete(params as Filter) }}
                            >
                                Delete
                            </button>
                        </div>
                    </>
                },
                width: "110px",
            },

        ];
    },
);

