import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const getId: any = document.getElementById('root');
const root = ReactDOM.createRoot(getId);

setTimeout(() => {
  root.render(<App />);
}, 1000);

