
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Alert, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { createUserCookies } from "src/globals";
import { useUserContext } from "src/globals/providers";
import { AuthService } from "src/services/AuthService";
import * as Yup from "yup";
import { PasswordField } from "./PasswordField";
import logo from "../../assets/images/logo.png";
import { UserService } from "src/services/UserService";
import { UserModules } from "src/sdk";

const service = new AuthService();

const userService = new UserService();

const Login = () => {
  const history = useHistory();
  const { user, setUser } = useUserContext();
  const [error, setError] = useState<null | string>(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "admin@synthesishealthinc.com" || '',
      password: "Password1!" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email"),
      password: Yup.string()
        .required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      service.login(values)
        .then(function (response) {
          if (setUser) {
            setUser(
              response.data
            );
          } else {
            setError("error");
          }
        })
        .catch(function (error) {
          setError("We couldn't find an account matching the login info you entered");
        })
    }
  });

  useEffect(() => {
    if (user) {
      createUserCookies(user);
      if (user?.role === "SuperAdministrator") {
        history.push("/admin-home");
      } 
      else {
        userService.getModulesByUserId()
            .then(function (response) {
                if(response.data.data[0] === undefined){
                  history.push("/user-home"); 
                }
                else {
                  history.push("/user-modules", response.data.data[0]); 
                }
            })
            .catch(function (error) {
            }); 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  document.title = "Login | Synth";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4 mt-5" style={{ backgroundColor: "#274755", color: "white" }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <img src={logo} />
                      </div>
                      <hr className="my-4"/>
                      <div className="text-center">
                        <h5 className="mb-0">Login</h5>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {/* //////////////////////////// */}
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <PasswordField
                            validation={validation}
                          />
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-light btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div className="mt-5 text-center">
                        <p className="text-body-secondary mb-0">
                          Don't have an account ?{" "}
                          <Link
                            to="/register"
                            className="text-light fw-semibold"
                          >
                            {" "}
                            Sign up now{" "}
                          </Link>{" "}
                        </p>
                      </div>
                      <div className="mt-2 text-center">
                        <p className="text-light mb-0">
                          <Link
                            to="/forgotPassword"
                            className="text-light fw-semibold"
                          >
                            {" "}
                            Forgot Password?{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);