import { useFormik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { Col, Container, Form, FormFeedback, Input, Row } from "reactstrap";
import { SuperAdminService } from "src/services/SuperAdminService";
import * as Yup from "yup";

const service = new SuperAdminService();
export const CreateModule = () => {
    const history = useHistory();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Please enter name"),
        }),
        onSubmit: (values: any) => {
            service.createModule(values)
                .then(function (response) {
                    history.push("/all-modules");
                    toast.success(`You have create new module ${values.name}!`);
                })
                .catch(function (error) {
                });
        }
    });

    document.title = "Create module | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
                        <Link style={{color:"black"}}
                            to="/all-modules"
                            className="nav_link"
                            >
                            <h5>Modules</h5>
                            </Link>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginLeft:13, opacity:0.6}}>Create module</h5>
                        </div>
                    </div>
                </div>
                <div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Container fluid className="p-5">
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-1 p-2">
                                    <h5>Name:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-11">
                                    <Input
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        placeholder="Enter module name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid"><>{validation.errors.name}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid>
                            <div className="d-flex flex-row-reverse">
                                <div className="p-1">
                                    <button
                                        className="btn btn-primary btn-block"
                                        style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="p-1">
                                    <Link
                                        className="btn btn-secondary btn-block"
                                        to="/all-modules"
                                    >
                                        Cancel
                                    </Link>
                                </div>
                            </div>
                        </Container>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}
CreateModule.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(CreateModule);