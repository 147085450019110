import { useFormik } from "formik";
import React, { useState } from "react";
import { Alert, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { ProfileService } from "src/services/ProfileService";
import * as Yup from "yup";

const service = new ProfileService();
export const ChangePassword = () => {

    const [error, setError] = useState<null | string>(null);
    const [success, setSuccess] = useState<null | string>(null);

    const [oldPassword, setOldPassword] = useState<boolean>(true);
    const [oldEye, setOldEye] = useState<string>("bi bi-eye-slash");
    const [oldType, setOldType] = useState<any>("password");

    const [newPassword, setNewPassword] = useState<boolean>(true);
    const [newEye, setNewEye] = useState<string>("bi bi-eye-slash");
    const [newType, setNewType] = useState<any>("password");

    const [confirmPassword, setConfirmPassword] = useState<boolean>(true);
    const [confirmEye, setConfirmEye] = useState<string>("bi bi-eye-slash");
    const [confirmType, setConfirmType] = useState<any>("password");

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .required("Please enter your old password"),
            newPassword: Yup.string()
                .required("Please enter your new password")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#[\]$%[\]^&[\]*.,;:{}<>+-/_=~`[]()?|]])(?=.{8,})/,
                    "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
                ),
            confirmPassword:
                Yup.string()
                    .required("Please confirm your new password")
                    .oneOf([Yup.ref("newPassword")], "Passwords do not match")
        }),
        onSubmit: (values: any) => {
            service.changePassword(values)
                .then(function (response) {
                    if (response.request.status === 204) {
                        setError("You have enter wrong old password. Try again.");
                        setSuccess(null);
                    } else {
                        setSuccess("You have change your password.");
                        setError(null);
                    }

                })
                .catch(function (error) {
                    setError("error");
                })
        }
    });

    document.title = "Change Password | Synth";
    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="justify-content-center">
                        <Col>
                            <div className="auth-full-page-content d-flex p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Change password</h5>
                                            </div>
                                            <Form
                                                className="custom-form mt-4 pt-2"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                {success ? <Alert color="success">{success}</Alert> : null}
                                                {error ? <Alert color="danger">{error}</Alert> : null}
                                                <Row className="mb-3">
                                                    <Col className="col-3 p-2">
                                                        <Label className="form-label">Old password</Label>
                                                    </Col>
                                                    <Col>
                                                        <div className="input-group">
                                                            <Input
                                                                name="oldPassword"
                                                                type={oldType}
                                                                className="form-control"
                                                                placeholder="Enter password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.oldPassword || ""}
                                                                invalid={
                                                                    validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                                                                }
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-primary rounded-0 rounded-end  ${oldEye}`}
                                                                    onClick={() => {
                                                                        if (oldPassword === true) {
                                                                            setOldPassword(false);
                                                                            setOldEye("bi bi-eye");
                                                                            setOldType("text");
                                                                        } else {
                                                                            setOldPassword(true);
                                                                            setOldEye("bi bi-eye-slash");
                                                                            setOldType("password");
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            {validation.touched.oldPassword && validation.errors.oldPassword ? (
                                                                <FormFeedback type="invalid"><>{validation.errors.oldPassword}</></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col className="col-3 p-2">
                                                        <Label className="form-label">New password</Label>
                                                    </Col>
                                                    <Col>
                                                        <div className="input-group">
                                                            <Input
                                                                name="newPassword"
                                                                type={newType}
                                                                className="form-control"
                                                                placeholder="Enter password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.newPassword || ""}
                                                                invalid={
                                                                    validation.touched.newPassword && validation.errors.newPassword ? true : false
                                                                }
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-primary rounded-0 rounded-end  ${newEye}`}
                                                                    onClick={() => {
                                                                        if (newPassword === true) {
                                                                            setNewPassword(false);
                                                                            setNewEye("bi bi-eye");
                                                                            setNewType("text");
                                                                        } else {
                                                                            setNewPassword(true);
                                                                            setNewEye("bi bi-eye-slash");
                                                                            setNewType("password");
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            {validation.touched.newPassword && validation.errors.newPassword ? (
                                                                <FormFeedback type="invalid"><>{validation.errors.newPassword}</></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col className="col-3 p-2">
                                                        <Label className="form-label">Confirm password</Label>
                                                    </Col>
                                                    <Col>
                                                        <div className="input-group">
                                                            <Input
                                                                name="confirmPassword"
                                                                type={confirmType}
                                                                className="form-control"
                                                                placeholder="Enter password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.confirmPassword || ""}
                                                                invalid={
                                                                    validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                                }
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-primary rounded-0 rounded-end  ${confirmEye}`}
                                                                    onClick={() => {
                                                                        if (confirmPassword === true) {
                                                                            setConfirmPassword(false);
                                                                            setConfirmEye("bi bi-eye");
                                                                            setConfirmType("text");
                                                                        } else {
                                                                            setConfirmPassword(true);
                                                                            setConfirmEye("bi bi-eye-slash");
                                                                            setConfirmType("password");
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                                <FormFeedback type="invalid"><>{validation.errors.confirmPassword}</></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="d-flex flex-row-reverse">
                                                        <div className="p-1">
                                                            <button
                                                                className="btn btn-primary btn-block"
                                                                type="submit"
                                                            >
                                                                Change Password
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default ChangePassword;