import classnames from "classnames";
import React, { useState } from "react";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { ChangePassword } from "./ChangePassword";
import { EditBasicInfo } from "./EditBasicInfo";

export const EditProfile = () => {

    const [activeTab, setactiveTab] = useState("1");

    const toggle = (tab: any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    document.title = "Edit user | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <Card style={{ border: 0 }}>
                            <CardBody >
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1");
                                            }}
                                        >
                                            Edit Basic Info
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2");
                                            }}
                                        >
                                            Change Password
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted ">
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col sm="12">
                                                <EditBasicInfo />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col sm="12">
                                                <ChangePassword />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

