import { useFormik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Col, Container, Form, Input, Modal, Row } from "reactstrap";
import { ExecutedActions, FieldForm, FieldValue } from "src/sdk";
import { UserService } from "src/services/UserService";
import moment from 'moment';



export interface DetailsProps {
    detailsItems: FieldValue,
    detailsActions: any,
    moduleRecordId: number,
    obj: FieldForm,
    modal_fullscreen: boolean;
    setmodal_fullscreen: (value: boolean) => void;
    tog_fullscreen: () => void;
    setUpdateActions: (value: boolean) => void;
}

const service = new UserService();
export const Details: React.FC<DetailsProps> = ({
    detailsItems,
    detailsActions,
    moduleRecordId,
    obj,
    modal_fullscreen,
    setmodal_fullscreen,
    tog_fullscreen,
    setUpdateActions,
}) => {

    const [action, setAction] = useState(null);

    const [actionId, setActionId] = useState(0);

    const [actionUsername, setActionUsername] = useState("");

    const [actionPassword, setActionPassword] = useState("");

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: obj,

        onSubmit: (values: any) => {
            const executedActionsData: ExecutedActions = {
                actionId: actionId,
                moduleRecordId: moduleRecordId as number,
                editedJson: JSON.stringify(values),
            }

            service.executedActions(executedActionsData)
                .then(function (response) {
                    let username = actionUsername;
                    let password = actionPassword;

                    let base64encodedData = Buffer.from(username + ':' + password).toString('base64');
                    /////////////////////////////
                    service.filemakerAuthentication(action, base64encodedData)
                        .then(function (fmresponse) {
                            service.getActionLinkByPublicId(action, response.data.item.publicId, fmresponse.data.response.token)
                                .then(function (response2) {
                                    service.closeFilemakerSession(action, fmresponse.data.response.token, base64encodedData)
                                        .then(function (fmresponse2) {
                                            setUpdateActions(true);
                                            setmodal_fullscreen(false);
                                            toast.success(`Message: ${JSON.parse(response2.data.response.scriptResult).message}  Status: ${JSON.parse(response2.data.response.scriptResult).status}`);
                                        })
                                        .catch(function (error) {
                                        })
                                })
                                .catch(function (error) {
                                })
                        })
                        .catch(function (error) {
                        })

                })
                .catch(function (error) {
                })
        }
    });

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Modal
                    isOpen={modal_fullscreen}
                    toggle={() => {
                        tog_fullscreen();
                    }}
                    scrollable={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Details</h5>
                        <button
                            onClick={() => {
                                setmodal_fullscreen(false);
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = "#808080";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = '#AAAAAA';
                            }}
                            data-dismiss="modal"
                            style={{ backgroundColor: "#AAAAAA", color: "#FFFFFF", border: 0, borderRadius: 60, gap: 5, width: 100, paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 20, display: "flex" }}
                            type="button"
                        >
                            <i className={`bi-x-circle`} />
                            Close
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={(e: any) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <>
                                {detailsItems.map((element: any, index: any) => {
                                    return (
                                        <div key={index} style={{ marginTop: 3, border: '1px solid #DEDEDE' }}>
                                            <div className="row" style={{ marginLeft: 0, marginRight: 0, backgroundColor: "#F7F7F7" }}>
                                                <p style={{ fontSize: 15, marginBottom: 5 }}>{element.label}</p>
                                            </div>
                                            <div>
                                                <p style={{ marginLeft: 10, marginTop: 5, fontWeight: "bold" }}>
                                                    {Object.values(obj)[index]}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        </Form>
                        <hr />
                        <div style={{ marginTop: 10 }}>
                            {detailsActions.map((element: any, index: any) => {
                                return (
                                    <div key={index} className="row" style={{ marginLeft: 0, marginRight: 0, marginTop: 5 }}>
                                        <button key={index}
                                            style={{ width: "100%", borderRadius: 60, border: 0, backgroundColor: "#95CBB2", color: "#FFFFFF" }}
                                            className="btn btn-primary btn-block"
                                            type="button"
                                            onClick={() => {
                                                console.log(element);
                                                const executedActionsData: ExecutedActions = {
                                                    actionId: element.actionId,
                                                    moduleRecordId: moduleRecordId as number,
                                                    editedJson: JSON.stringify(obj),
                                                }

                                                service.executedActions(executedActionsData)
                                                    .then(function (response) {
                                                        let username = element.username;
                                                        let password = element.password;

                                                        let base64encodedData = Buffer.from(username + ':' + password).toString('base64');
                                                        /////////////////////////////
                                                        service.filemakerAuthentication(element, base64encodedData)
                                                            .then(function (fmresponse) {
                                                                let scriptParam = {
                                                                    publicId: response.data.item.publicId,
                                                                    param: obj.hasOwnProperty(element.scriptParam) ? obj[element.scriptParam] : element.scriptParam
                                                                };
                                                                
                                                                service.getActionLinkByPublicId(element, JSON.stringify(scriptParam), fmresponse.data.response.token)
                                                                    .then(function (response2) {
                                                                        service.closeFilemakerSession(element, fmresponse.data.response.token, base64encodedData)
                                                                            .then(function (fmresponse2) {
                                                                                setUpdateActions(true);
                                                                                toast.success(`Message: ${JSON.parse(response2.data.response.scriptResult).message}  Status: ${JSON.parse(response2.data.response.scriptResult).status}`);
                                                                            })
                                                                            .catch(function (error) {
                                                                            })
                                                                    })
                                                                    .catch(function (error) {
                                                                    })
                                                            })
                                                            .catch(function (error) {
                                                            })
                                                    })
                                                    .catch(function (error) {
                                                    })
                                            }
                                            }
                                        >
                                            {element.name}
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    );
}

Details.propTypes = {

};
export default withTranslation()(Details);
