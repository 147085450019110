import { Redirect } from "react-router-dom";

import Logout from "src/pages/Authentication/Logout";

import ForgotPassword from "src/pages/Authentication/ForgotPassword";
import Login from "src/pages/Authentication/Login";
import Register from "src/pages/Authentication/Register";
import ResetPassword from "src/pages/Authentication/ResetPassword";
import ResetPasswordSuccess from "src/pages/Authentication/ResetPasswordSuccess";

//table
import BasicTable from "src/pages/Tables/BasicTables";

import AdminHome from "src/pages/Dashboard/AdminHome";
import UserHome from "src/pages/Dashboard/UserHome";


import RegisterSuccess from "src/pages/Authentication/RegisterSuccess";
import { EditProfile } from "src/pages/Profile/EditProfile";
import DynamicModules from "src/pages/Users/DynamicModules";
import { CreateModule, EditModule, ModulesTable } from "src/pages/Admin/Modules";
import { CreateField, EditField, ModuleFields } from "src/pages/Admin/ModuleFields";
import { ModuleActions } from "src/pages/Admin/ModuleActions";
import CreateAction from "src/pages/Admin/ModuleActions/CreateAction";
import EditAction from "src/pages/Admin/ModuleActions/EditAction";
import UsersTable from "src/pages/Admin/UserManagement/UsersTable";
import { CreateUser, EditUser } from "src/pages/Admin/UserManagement";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const adminRoutes: Array<RouteProps> = [
  //AppHeader
  { path: "/admin-edit-profile", component: EditProfile },
  { path: "/logout", component: Logout },

  //Admin sidebar
  { path: "/admin-home", component: AdminHome },
  { path: "/all-modules", component: ModulesTable },
  { path: "/tables-basic", component: BasicTable },

  //Module
  { path: "/create-module", component: CreateModule },
  { path: "/edit-module", component: EditModule },
  { path: "/fields-module", component: ModuleFields },
  { path: "/actions-module", component: ModuleActions },

  //Field
  { path: "/create-field", component: CreateField },
  { path: "/edit-field", component: EditField },

  //Actions 
  { path: "/create-action", component: CreateAction },
  { path: "/edit-action", component: EditAction },

  //User's Managment
  { path: "/users-managment", component: UsersTable },
  { path: "/create-user", component: CreateUser },
  { path: "/edit-usersManagment", component: EditUser },



  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/admin-home" /> },
];

const userRoutes: Array<RouteProps> = [
  //AppHeader
  { path: "/user-edit-profile", component: EditProfile },
  { path: "/logout", component: Logout },

  //User sidebar
  { path: "/user-home", component: UserHome },

  //UsersModule
  { path: "/user-modules", component: DynamicModules },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/user-modules" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/register-success", component: RegisterSuccess },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/resetPassword", component: ResetPassword },
  { path: "/resetPasswordSuccess", component: ResetPasswordSuccess },
];

export { adminRoutes, userRoutes, authRoutes };

