export const DefaultThemeForReactTable = () => ({
  table: {
    style: {
      minHeight: "270px"
    },
  },
  headRow: {
    style: {
      fontSize: 12,
      height: "40px",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      width: "10px",
      borderLeft: `1px solid #dee2e6`,
      borderRight: `1px solid #dee2e6`,
      backgroundColor: "#274755",
      color: "#FFFFFF",

      height: "40px",
      maxHeight: "40px"
    },
  },
  rows: {
    style: {
      borderBottomWidth: "0px !important",
    },
  },
  cells: {
    style: {
      width: "10px",
      height: "50px",
      fontSize: 12,
      border: "1px solid #dee2e6",
      fontWeight: "bold"
    },
  },
  pagination: {
    style: {
    },
  },

});