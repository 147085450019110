import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable, { SortOrder } from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Loader } from "src/components";
import { DefaultThemeForReactTable } from "src/components/Table";
import { Actions, getPaginationRequestInitialValue, Modules, PaginationRequest } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import Swal from 'sweetalert2';
import { ActionsModuleColumns } from "./Components/ModuleActionsColumns";
import classnames from "classnames";

const service = new SuperAdminService();
export const ModuleActions = () => {
    const location = useLocation();
    const history = useHistory();

    const [actionsData, setActionsData] = useState<Actions[]>([]);
    const [loading, setLoading] = useState(false);
    const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false);
    const [totalRows, setTotalRows] = useState(10000);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string>("");
    const [sortBy, setSortBy] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [activeTab, setactiveTab] = useState("1");
    const [headerValue, setHeaderValue] = useState("Edit");

    const toggle = (tab: any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    /////////////////////////////

    useEffect(() => {
        activeTab === "1" ? setHeaderValue("Module Records Actions") : setHeaderValue("Module Actions");
    }, [activeTab]);

    /////////////////////////////search
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            search: ""

        },
        onSubmit: (values: any) => {
            fetchPage(page, values.search, true, sortBy, sortOrder);
            setSearch(values.search);
        }
    });

    ////////////////////////Pagination
    const fetchPage = async (page: any, search: any, paginationResetDefaultPage: any, sortBy: any, sortOrder: any) => {
        setLoading(true);

        let pagination: PaginationRequest = getPaginationRequestInitialValue();
        pagination.page = page;
        pagination.pageSize = perPage;
        pagination.search = search;
        pagination.moduleId = location.state.moduleId;
        pagination.sortBy = sortBy;
        pagination.sortOrder = sortOrder;

        getActions(setActionsData, pagination, setTotalRows, setLoading);
        setPaginationResetDefaultPage(paginationResetDefaultPage);
    };

    useEffect(() => {
        fetchPage(page, "", false, sortBy, sortOrder); // fetch page 1 of users

    }, []);

    const handlePageChange = (pageNew: any) => {
        fetchPage(pageNew, search, false, sortBy, sortOrder);
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);
        let pagination: PaginationRequest = getPaginationRequestInitialValue();
        pagination.page = page;
        pagination.pageSize = newPerPage;
        pagination.search = search;
        pagination.moduleId = location.state.moduleId;
        pagination.sortBy = sortBy;
        pagination.sortOrder = sortOrder;

        getActions(setActionsData, pagination, setTotalRows, setLoading);
        setPerPage(newPerPage);
    };

    const sortPagination = (state: any, sortDirection: SortOrder) => {
        setSortOrder(sortDirection);
        setSortBy(state.id);
        fetchPage(page, search, false, state.id, sortDirection); // fetch page 1 of users
    }

    /////////////////////// Style Pagination
    const paginationComponentOptions = {
        rowsPerPageText: "Rows per page",
        rangeSeparatorText: "of",
        selectAllRowsItem: false,
    };

    //////////////////////////////////////////////////////
    const onEditAction = (values: Actions) => {
        history.push({
            pathname: '/edit-action',
            state: { actions: values, module: location.state }
        })
    }

    const onDeleteAction = (actionId: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your module has been deleted.',
                    'success'
                )
                service.deleteAction(actionId)
                    .then(function (response) {
                        setActionsData([
                            ...actionsData.filter((element) => {
                                return element.actionId !== actionId
                            }),
                        ]);
                    })
                    .catch(function (error) {
                    });
            }
        })
    }
    //////////////////////////////////////    }

    document.title = "Actions | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
                        <Link style={{color:"black"}}
                            to="/all-modules"
                            className="nav_link"
                            >
                            <h5>Modules</h5>
                            </Link>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginRight:13, marginLeft:13}}>{location.state.name} Module</h5>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginLeft:13, opacity:0.6}}>Actions List</h5>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginLeft:13, opacity:0.6}}>{headerValue}</h5>
                        </div>
                    </div>
                </div>
                {/* ////////////////////////////////////////////////////// */}
                <Row>
                    <Col xl={12}>
                        <Card style={{ border: 0 }}>
                            <CardBody >
                                <div className="row">
                                    <div className="col-2">
                                        <Nav tabs className="flex-column nav nav-pills">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer", backgroundColor: activeTab === "2" ? "white" : "#6c757d", color: activeTab === "2" ? "black" : "white" }}
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggle("1");
                                                    }}
                                                >
                                                    Module Records Actions
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer", backgroundColor: activeTab === "1" ? "white" : "#6c757d", color: activeTab === "1" ? "black" : "white" }}

                                                        className={classnames({
                                                            active: activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggle("2");
                                                        }}
                                                    >
                                                        Module Actions
                                                    </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <div className="col-10">
                                        <TabContent activeTab={activeTab} className="text-muted ">
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12">
                                                        <div className="mb-2 d-flex flex-row-reverse">
                                                                <Link
                                                                    className="btn btn-secondary btn-block"
                                                                    to={{
                                                                        pathname: '/create-action',
                                                                        state: location.state as Modules,
                                                                        isModule: false
                                                                    }}
                                                                >
                                                                    Add action
                                                                </Link>
                                                        </div>
                                                        {/* ////////////////////////////////////////////////// */}
                                                        <div style={{ width: "100%" }} >
                                                            <div className="table-responsive">
                                                                <DataTable
                                                                    data={actionsData.filter(a => a.isModuleAction === false)}
                                                                    columns={ActionsModuleColumns(onEditAction, onDeleteAction)}
                                                                    customStyles={DefaultThemeForReactTable()}
                                                                    progressPending={loading}
                                                                    progressComponent={<Loader loading={loading} />}
                                                                    ///////////////Pagination
                                                                    pagination
                                                                    paginationServer
                                                                    paginationTotalRows={totalRows}
                                                                    paginationResetDefaultPage={paginationResetDefaultPage}
                                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                                    pointerOnHover={true}
                                                                    onChangePage={handlePageChange}
                                                                    paginationComponentOptions={paginationComponentOptions}
                                                                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                                                    onSort={sortPagination}
                                                                    defaultSortAsc={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12">
                                                    <div className="mb-2 d-flex flex-row-reverse">
                                                                <Link
                                                                    className="btn btn-secondary btn-block"
                                                                    to={{
                                                                        pathname: '/create-action',
                                                                        state: location.state as Modules,
                                                                        isModule: true
                                                                    }}
                                                                >
                                                                    Add action
                                                                </Link>
                                                        </div>
                                                        {/* ////////////////////////////////////////////////// */}
                                                        <div style={{ width: "100%" }} >
                                                            <div className="table-responsive">
                                                                <DataTable
                                                                    data={actionsData.filter(a => a.isModuleAction === true)}
                                                                    columns={ActionsModuleColumns(onEditAction, onDeleteAction)}
                                                                    customStyles={DefaultThemeForReactTable()}
                                                                    progressPending={loading}
                                                                    progressComponent={<Loader loading={loading} />}
                                                                    ///////////////Pagination
                                                                    pagination
                                                                    paginationServer
                                                                    paginationTotalRows={totalRows}
                                                                    paginationResetDefaultPage={paginationResetDefaultPage}
                                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                                    pointerOnHover={true}
                                                                    onChangePage={handlePageChange}
                                                                    paginationComponentOptions={paginationComponentOptions}
                                                                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                                                    onSort={sortPagination}
                                                                    defaultSortAsc={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

ModuleActions.propTypes = {

};

export default ModuleActions;

export const getActions = (setActionsData: any, pagination: any, setTotalRows: any, setLoading: any) => {

    service.getActions(pagination)
        .then(function (response) {
            setTotalRows(response.data.totalRecords);
            setActionsData(response.data.data);
            setLoading(false)
        })
        .catch(function (error) {
        })
} 