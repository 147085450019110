import React from "react";
//reactstrap
import { Col, Container, Row } from "reactstrap";

import { useHistory, withRouter } from "react-router-dom";


const ResetPasswordSuccess = () => {
    const history = useHistory();

    document.title = "Reset Password Success| Synth";
    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={4} md={5} className="col-xxl-4">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Whoo Whoo!</h5>
                                                <p className="text-muted mt-2">
                                                    Your password has been reset successfully!
                                                </p>
                                                <p className="text-muted mt-2">
                                                    Now login with your new password!
                                                </p>
                                            </div>
                                            <div className="mb-3">
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                    onClick={() => {
                                                        history.push("/login");
                                                    }}
                                                >
                                                    Login
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ResetPasswordSuccess);