import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useUserContext } from "src/globals/providers";
import { removeCookie, CookiesKey } from "src/globals";


const Logout = () => {
    const { user, setUser } = useUserContext();
    let history = useHistory();

    useEffect(() => {
        if (setUser) {
            setUser(null);
        }
        removeCookie(CookiesKey.USER_COOKIE);
        removeCookie(CookiesKey.JWT_TOKEN);
        history.push("/login");
    }, []);


    return <></>;
};

export default withRouter(Logout);
