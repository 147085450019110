import React, { useState } from "react";
import { FormFeedback, Input, Label } from "reactstrap";

export interface PasswordConfirmFieldeldProps {
    validation: any;
}
export const PasswordConfirmField: React.FC<PasswordConfirmFieldeldProps> = ({
    validation
}) => {
    const [password, setPassword] = useState<boolean>(true);
    const [eye, setEye] = useState<string>("bi bi-eye-slash");
    const [type, setType] = useState<any>("password");
    return (
        <React.Fragment>

            <Label className="form-label">Confirm Password</Label>
            <div className="input-group">
                <Input
                    name="confirmPassword"
                    type={type}
                    placeholder="Confirm password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.confirmPassword || ""}
                    invalid={
                        validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                    }
                />
                <div className="input-group-append">
                    <button
                        type="button"
                        className={`btn btn-light rounded-0 rounded-end  ${eye}`}
                        onClick={() => {
                            if (password === true) {
                                setPassword(false);
                                setEye("bi bi-eye");
                                setType("text");
                            } else {
                                setPassword(true);
                                setEye("bi bi-eye-slash");
                                setType("password");
                            }
                        }}
                    />
                </div>
                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                    <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                ) : null}
            </div>
        </React.Fragment>
    );
}
PasswordConfirmField.propTypes = {

};