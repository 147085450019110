
import PropTypes from 'prop-types';
import { useState } from 'react';
import AppHeader from './Header/AppHeader';
import UserSidebar from './Sidebar/UserSidebar';
import './Style/index.css';

interface UserLayoutProps {
  children: any;
}
export const UserLayout = (props: UserLayoutProps) => {
  const [showNav, setShowNav] = useState(true)

  return (
    <>
      <div className={`body-area${showNav ? ' body-pd' : ''}`}>
        <UserSidebar showNav={showNav} />
        <AppHeader showNav={showNav} setShowNav={setShowNav} />
        <div className="container-fluid p-5">
          {props.children}
        </div>
      </div>
    </>
  );
}
UserLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default UserLayout;
