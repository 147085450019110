import React, { useState } from "react";
import {
    Dropdown, DropdownMenu, DropdownToggle
} from "reactstrap";

import FontAwesome from 'react-fontawesome';
import { Fields } from "src/sdk";


export interface FieldsDropdownProps {
    values?: Fields;
    onEditField?: (values: Fields) => void;
    id?: number;
    onDeleteField?: (id: number) => void;
}

const FieldsDropdown: React.FC<FieldsDropdownProps> = ({
    values,
    onEditField,
    id,
    onDeleteField,
}) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false);
    const [name, setName] = useState("Options");

    const editField = () => {
        if (onEditField) onEditField(values as Fields);
    };

    const deleteField = () => {
        if (onDeleteField) onDeleteField(id as number);
    };
    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item bg-soft-light"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <FontAwesome name="caret-down" />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
                </DropdownToggle>
                <DropdownMenu 
                    className="dropdown-menu-end"
                    style={{ width: "150px" }}>
                    <div className="dropdown-item" onClick={editField}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Edit</span>
                    </div>
                    <div className="dropdown-item" onClick={deleteField}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        <span>Delete</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

FieldsDropdown.propTypes = {

};
export default FieldsDropdown;
