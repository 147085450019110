import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserModules } from "src/sdk";
import { UserService } from "src/services/UserService";
import logo from "../../assets/images/logo.png";

export interface UserSidebarProps {
    showNav: boolean;
}

const service = new UserService()
const UserSidebar: React.FC<UserSidebarProps> = ({
    showNav,
}) => {

    const [userModulesData, setUserModulesData] = useState<UserModules[]>([]);
    const [menuItems, setMenuItems] = useState<UserModules[]>([]);
    
    useEffect(() => {
        service.getModulesByUserId()
            .then(function (response) {
                setUserModulesData(response.data.data);
            })
            .catch(function (error) {
            });
    }, []);

    return (
        <React.Fragment>
            <div className={`l-navbar${showNav ? ' l-show' : ''}`}>
                <nav className="custom_nav border-end">
                    <div className="container-fluid " >
                        <div className="col-12 col-md-3 col-xl-2 bd-sidebar  min-vh-100 " style={{ width: "200px" }}>
                            <div className="align-items-left text-start">
                                <div className="nav_list">
                                    <Link
                                        to={{
                                            pathname: '/user-modules',
                                            state: userModulesData[0]
                                        }}
                                        className="nav_logo">
                                        <span className="nav_logo-icon">
                                            <img src={logo} />
                                        </span>
                                    </Link>
                                    {/* <Link
                                        to="/user-home"
                                        className="nav_link">
                                        <i className='bi bi-house-door nav_icon' />
                                        <span className="nav_name">  Home </span>
                                    </Link> */}

                                    {userModulesData.map((userModule, index) => {
                                        return <Link key={index}
                                            to={{
                                                pathname: '/user-modules',
                                                state: userModule
                                            }}
                                            className="nav_link">
                                            <button type="button" className="btn btn-transparent p-1 text-white">
                                                <span className='avatar-title bg-transparent text-reset'>
                                                    {index + 1}
                                                </span>
                                            </button>
                                            <span className="nav_name">
                                                {userModule.moduleName}
                                            </span>
                                        </Link>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    );
};

UserSidebar.propTypes = {
};

export default withTranslation()(UserSidebar);
