import { useFormik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Fields, Modules } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import * as Yup from "yup";

const service = new SuperAdminService();
export const CreateField = () => {
    const location = useLocation();
    const history = useHistory();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            moduleId: location.state.moduleId,
            name: "",
            label: "",
            orderNo: 0,
            type: "string",
            sortable: false,
            showOnList: false,
            showOnForm: false,
            filterField: false,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Please enter name"),
            label: Yup.string()
                .required("Please enter label"),
            orderNo: Yup.string()
                .required("Please enter orderNo"),
        }),
        onSubmit: (values: any) => {
            let field: Fields = {
                moduleId: values.moduleId,
                name: values.name,
                label: values.label,
                orderNo: values.orderNo,
                type: values.type,
                sortable: JSON.parse(values.sortable),
                showOnList: JSON.parse(values.showOnList),
                showOnForm: JSON.parse(values.showOnForm),
                isFilterField: JSON.parse(values.filterField),
            }
            
            service.createField(field)
                .then(function (response) {
                    history.push({
                        pathname: '/fields-module',
                        state: location.state as Modules,
                    })
                    toast.success(`You have create new field ${field.name}!`);
                })
                .catch(function (error) {
                });
        }
    });

    document.title = "Create field | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
                        <Link style={{color:"black"}}
                            to="/all-modules"
                            className="nav_link"
                            >
                            <h5>Modules</h5>
                            </Link>
                        <i className="bi bi-chevron-right"></i>
                        <Link style={{color:"black", marginLeft:10}}
                            to={{
                                pathname: '/fields-module',
                                state: location.state as Modules
                            }}
                            className="nav_link"
                            >
                            <h5>{location.state.name} Module</h5>
                            </Link>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginLeft:13, opacity:0.6}}>Create Field</h5>
                        </div>
                    </div>
                </div>
                
                <div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Container fluid className="p-5">
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-1 p-2">
                                    <h5>Name:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-11">
                                    <Input
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        placeholder="Enter module name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid"><>{validation.errors.name}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-1 p-2">
                                    <h5>Label:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-11">
                                    <Input
                                        id="label"
                                        name="label"
                                        className="form-control"
                                        placeholder="Enter module label"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.label || ""}
                                        invalid={
                                            validation.touched.label && validation.errors.label ? true : false
                                        }
                                    />
                                    {validation.touched.label && validation.errors.label ? (
                                        <FormFeedback type="invalid"><>{validation.errors.label}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-1 p-2">
                                    <h5>OrderNo:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-11">
                                    <Input
                                        id="orderNo"
                                        name="orderNo"
                                        className="form-control"
                                        placeholder="Enter module orderNo"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.orderNo || ""}
                                        invalid={
                                            validation.touched.orderNo && validation.errors.orderNo ? true : false
                                        }
                                    />
                                    {validation.touched.orderNo && validation.errors.orderNo ? (
                                        <FormFeedback type="invalid"><>{validation.errors.orderNo}</></FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-1 p-2">
                                    <h5>Type:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-11">
                                    <Input
                                        id="type"
                                        name="type"
                                        className="form-control"
                                        type="select"
                                        onChange={validation.handleChange}
                                    >
                                        <option disabled>Choose a type</option>
                                        <option>string</option>
                                        <option>number</option>
                                        <option>boolean</option>
                                        <option>date</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Is sortable:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10 p-2">
                                    <FormGroup tag="fieldset" className="m-auto">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    name="sortable"
                                                    type="checkbox"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value="false"
                                                />
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Show on list:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10 p-2">
                                    <FormGroup tag="fieldset" className="m-auto">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    name="showOnList"
                                                    type="checkbox"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value="false"
                                                />
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Show on form:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10 p-2">
                                    <FormGroup tag="fieldset" className="m-auto">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    name="showOnForm"
                                                    type="checkbox"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value="false"
                                                />
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={4} md={5} className="col-xxl-2 p-2">
                                    <h5>Filter field:</h5>
                                </Col>
                                <Col lg={4} md={5} className="col-xxl-10 p-2">
                                    <FormGroup tag="fieldset" className="m-auto">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    name="filterField"
                                                    type="checkbox"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value="false"
                                                />
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid>
                            <div className="d-flex flex-row-reverse">
                                <div className="p-1">
                                    <button
                                        className="btn btn-primary btn-block"
                                        style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="p-1">
                                    <Link
                                        className="btn btn-secondary btn-block"
                                        to={{
                                            pathname: '/fields-module',
                                            state: location.state as Modules
                                        }}
                                    >
                                        Cancel
                                    </Link>
                                </div>
                            </div>
                        </Container>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}
CreateField.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(CreateField);