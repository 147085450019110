import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { Fields } from "src/sdk";
import FieldsDropdown from "./DropdownComponent";

export const ModduleFieldsColumns = memoizeOne(
    (
        onEditField: (values: Fields) => void,
        onDeleteField: (id: number) => void,
    ): TableColumn<Fields>[] => {
        return [
            {
                id: "fieldId",
                selector: (row: any) => row.fieldId,
                sortable: true,
                name: "Field id",
                width: "106px",
            },
            {
                id: "name",
                selector: (row: any) => row.name,
                sortable: true,
                name: "Name",
            },
            {
                id: "label",
                selector: (row: any) => row.label,
                sortable: true,
                name: "Label",
            },
            {
                id: "orderNo",
                selector: (row: any) => row.orderNo,
                sortable: true,
                name: "OrderNo",
            },
            {
                id: "type",
                selector: (row: any) => row.type,
                sortable: true,
                name: "Type",
            },
            {
                id: "sortable",
                sortable: true,
                name: "Sortable",
                cell: (row: any) => {
                    return (
                        <div className="m-auto h4">
                            {
                                row.sortable === true ? <span className=" bi bi-check-lg green-color"> </span> : <span className=" bi bi-x red-color"> </span>
                            }
                        </div>
                    );
                },
            },
            {
                id: "showOnList",
                sortable: true,
                name: "Show on list",
                cell: (row: any) => {
                    return (
                        <div className="m-auto h4">
                            {
                                row.showOnList === true ? <span className=" bi bi-check-lg green-color"> </span> : <span className=" bi bi-x red-color"> </span>
                            }
                        </div>
                    );
                },
            },
            {
                id: "showOnForm",
                sortable: true,
                name: "Show on form",
                cell: (row: any) => {
                    return (
                        <div className="m-auto h4">
                            {
                                row.showOnForm === true ? <span className=" bi bi-check-lg green-color"> </span> : <span className=" bi bi-x red-color"> </span>
                            }
                        </div>
                    );
                },
            },
            {
                cell: (row: any) => {
                    return (
                        <div className="m-auto">
                            <div>
                                <FieldsDropdown
                                    values={row as Fields}
                                    id={row.fieldId as number}
                                    onEditField={onEditField}
                                    onDeleteField={onDeleteField}
                                />
                            </div>
                        </div>
                    );
                },
                width: "140px",
            },
        ];
    },
);