export enum CookiesKey {
    USER_COOKIE = "UserCookie",
    JWT_TOKEN = "JwtToken",
    USER_CLAIMS = "UserClaims",
}

export type svgType = React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;
