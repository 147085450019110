import axios from "axios";
import Cookies from "js-cookie";
import { apiRoutesForContext } from "src/routes/apiRoutes/react-api-route-paths";
import { ApproveRegistrationUser, Filter, Modules, UserActions, UserModules, Users } from "src/sdk";
import { Actions, FieldMappings, Fields, PaginationRequest } from './../sdk/models/SuperAdminModules';
import { BaseService } from "./BaseService";

const tmp = apiRoutesForContext();
export class SuperAdminService extends BaseService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }

    getAllModules(): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getModules() + "?takeAll=true",
        })
    }

    getModules(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getModules() + "?pageSize=" + data.pageSize + "&page=" + data.page + "&search=" + data.search + "&sortBy=" + data.sortBy + "&sortOrder=" + data.sortOrder,
        })
    }

    createModule(data: Modules): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().createModule(),
            data: data
        })
    }

    editModule(data: Modules): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "put",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().editModule() + "/" + data.moduleId,
            data: data
        })
    }

    deleteModule(id: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "delete",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().deleteModule() + "/" + id,
        })
    }
    /* Fields */
    createField(data: Fields): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().createField(),
            data: data
        })
    }

    getFields(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getFields() + "?pageSize=" + data.pageSize + "&page=" + data.page,
        })
    }

    getFieldById(id: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getFields() + "/" + id,
        })
    }

    getFieldsByModuleIdWithPagination(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getFields() + "?moduleId=" + data.moduleId + "&pageSize=" + data.pageSize + "&page=" + data.page + "&sortBy=" + data.sortBy + "&sortOrder=" + data.sortOrder,
        })
    }

    getFieldsByModuleId(moduleId: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getFields() + "?moduleId=" + moduleId + "&OnlyFilterFields=true",
        })
    }

    editFields(data: Fields): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "put",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().editField() + "/" + data.fieldId,
            data: data
        })
    }

    deleteField(id: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "delete",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().deleteField() + "/" + id,
        })
    }

    mappingsField(data: FieldMappings): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().mappingsField(),
            data: data
        })
    }

    getMappingsFieldbByFieldId(fieldId: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getMappingsFieldbByFieldId() + "/" + fieldId,
        })
    }

    editMappingsField(data: FieldMappings): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "put",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().editMappingsField() + "/" + data.apiMappingId,
            data: data
        })
    }
    /* UsersManagment */
    createUser(data: Users): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().createUser(),
            data: data
        })
    }

    getUsers(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getUsers() + "?pageSize=" + data.pageSize + "&page=" + data.page + "&search=" + data.search + "&sortBy=" + data.sortBy + "&sortOrder=" + data.sortOrder,
        })
    }

    editUser(data: Users): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "put",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().editUser() + "/" + data.userId,
            data: data
        })
    }

    deleteUser(userId: string): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "delete",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().deleteUser() + "/" + userId,
        })
    }

    approveRegistrationUser(data: ApproveRegistrationUser): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().approveRegistrationUser(),
            data: data
        })
    }
    //////////////////////////////////////////UserModules

    ////////asign Module to user, using checkbox on UsersManagment
    asignModule(data: UserModules): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().asignModule(),
            data: data
        })
    }
    getUserModules(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getUserModules() + "?pageSize=" + data.pageSize + "&page=" + data.page + "&search=" + data.search + "&userId=" + data.userId + "&sortBy=" + data.sortBy + "&sortOrder=" + data.sortOrder,
        })
    }

    /////////////////////* Actions
    createAction(data: Actions): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().createAction(),
            data: data
        })
    }
    getActions(data: PaginationRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getActions() + "?moduleId=" + data.moduleId + "&pageSize=" + data.pageSize + "&page=" + data.page + "&search=" + data.search + "&sortBy=" + data.sortBy + "&sortOrder=" + data.sortOrder,
        })
    }
    deleteAction(actionId: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "delete",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().deleteAction() + "/" + actionId,
        })
    }
    editAction(data: Actions): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "put",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().editAction() + "/" + data.actionId,
            data: data
        })
    }

    //////////////////////UsersManagement Filter
    createFilterValue(data: Filter): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().createFilterValue(),
            data: data
        })
    }
    getFilterValue(userModuleId: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getFilterValue() + "?userModuleId=" + userModuleId,
        })
    }
    deleteFilterValue(userFilterFieldId: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "delete",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().deleteFilterValue() + "/" + userFilterFieldId,
        })
    }
    //////////////////////UsersManagement Actions
    asignUserActions(data: UserActions): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().asignUserActions(),
            data: data
        })
    }
    getUserActions(userModuleId: number, moduleId: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().getUserActions() + "?userModuleId=" + userModuleId + "&moduleId=" + moduleId,
        })
    }
    ///////////////////Change days back
    updateDaysBack(data: UserModules): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().updateDaysBack(),
            data: data
        })
    }

    ///////////////////Set Filter By ExternalUserId
    setFilterByExternalUserId(data: UserModules): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().setFilterByExternalUserId(),
            data: data
        })
    }
}
