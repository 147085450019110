import { Redirect, Route } from "react-router-dom";
import { CookiesKey } from "src/globals";
import { useUserContext } from "src/globals/providers";
import { getCookie } from "src/globals/cookie";
import { useEffect } from "react";
import { User } from "src/sdk";


interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  isAdminLayout: any;
  path?: string;
  exact?: boolean;
  key?: number;
}

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  isAdminLayout,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout;
  const Component = component;

  const { user, setUser } = useUserContext();

  const userJsonValues = getCookie(CookiesKey.USER_COOKIE);
    useEffect(() => {
        if (!user && setUser) {
            //okay i get it user do have cookies but he isn't login so
            //get the cookies are create the sessions
            if (userJsonValues) {
                setUser(JSON.parse(userJsonValues) as User);
            }
        }
    }, [userJsonValues]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !getCookie(CookiesKey.JWT_TOKEN)) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        if (!isAuthProtected && getCookie(CookiesKey.JWT_TOKEN) && user?.role === "SuperAdministrator") {
          return (
            <Redirect
              to={{ pathname: "/admin-home", state: { from: props.location } }}
            />
          );
        }

        if (!isAuthProtected && getCookie(CookiesKey.JWT_TOKEN) && user?.role === "User") {
          return (
            <Redirect
              to={{ pathname: "/user-home", state: { from: props.location } }}
            />
          );
        }

        if (isAuthProtected && getCookie(CookiesKey.JWT_TOKEN) && user?.role === "SuperAdministrator" && !isAdminLayout) {
          return (
            <Redirect
              to={{ pathname: "/admin-home", state: { from: props.location } }}
            />
          );
        }

        if (isAuthProtected && getCookie(CookiesKey.JWT_TOKEN) && user?.role === "User" && isAdminLayout && path !== "/logout" && path !== "/changePassword") {
          return (
            <Redirect
              to={{ pathname: "/user-home", state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Authmiddleware;
