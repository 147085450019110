export interface Register {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    confirmPassword: string;
    clientConfirmationEmailURI: string;
}

export interface EmailComfirmationURL {
    userId: string;
    code: string;
}

export const getEmailComfirmationURLInitialValues =
    (): EmailComfirmationURL => {
        return {
            userId: "",
            code: "",
        };
    };