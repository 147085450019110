import { useFormik } from "formik";
import React from "react";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { AuthService } from "src/services/AuthService";
import * as Yup from "yup";
import { PasswordConfirmField } from "./PasswordConfirmField";
import { PasswordField } from "./PasswordField";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const service = new AuthService();
const ResetPassword = () => {
    const history = useHistory();
    let query = useQuery();
    let code = query.get("code") as string;

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            password: "",
            confirmPassword: "",
            code: code,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required("Please Enter Your Email")
                .email("Valid email required"),
            password: Yup.string()
                .required("This field is required")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#[\]$%[\]^&[\]*.,;:{}<>+-/_=~`[]()?|]])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
            confirmPassword:
                Yup.string()
                    .required("Please confirm your password")
                    .oneOf([Yup.ref("password")], "Passwords do not match")
        }),
        onSubmit: (values) => {
            service.resetPassword(values)
                .then(function (response) {
                    history.push("/resetPasswordSuccess");
                })
                .catch(function (error) {
                })
        }
    });

    document.title = "Reset Password | Synth";
    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Reset your password</h5>
                                            </div>
                                            <Form
                                                className="needs-validation custom-form mt-4 pt-2"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <div className="mb-3">
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <PasswordField
                                                        validation={validation}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <PasswordConfirmField
                                                        validation={validation}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Reset password
                                                    </button>
                                                </div>
                                            </Form>
                                            <div className="mt-3 text-center">
                                                <p className="text-muted mb-0">
                                                    Or?{"  "}
                                                    <Link
                                                        to="/login"
                                                        className="text-primary fw-semibold"
                                                    >
                                                        {" "}
                                                        Login{" "}
                                                    </Link>{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ResetPassword);