import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { FieldMappings, Modules, getFieldMappingsInitialValue } from "src/sdk/models/SuperAdminModules";
import { SuperAdminService } from "src/services/SuperAdminService";
import EditFieldTab from "./Components/EditFieldTab";
import SourceFieldTab from "./Components/SourceFieldTab";
import { Link } from "react-router-dom";

const service = new SuperAdminService();
export const EditField = () => {
    const location = useLocation();
    const [activeTab, setactiveTab] = useState("1");
    const [headerValue, setHeaderValue] = useState("Edit");
    const [mappingField, setMappingsField] = useState<FieldMappings>(getFieldMappingsInitialValue);

    const toggle = (tab: any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    useEffect(() => {
        activeTab === "1" ? setHeaderValue("Edit") : setHeaderValue("Map");
    }, [activeTab]);

    useEffect(() => {
        service.getMappingsFieldbByFieldId(location.state.fields.fieldId as number)
            .then(function (response) {
                if (response.data.item !== null) {
                    setMappingsField(response.data.item);
                }
            })
            .catch(function (error) {
            })
    }, []);
    document.title = "Edit user | Synth";
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div style={{paddingTop:10, paddingBottom:20, display:"flex", flexDirection:"row"}}>
                        <Link style={{color:"black"}}
                            to="/all-modules"
                            className="nav_link"
                            >
                            <h5>Modules</h5>
                            </Link>
                        <i className="bi bi-chevron-right"></i>
                        <Link style={{color:"black", marginLeft:10}}
                            to={{
                                pathname: '/fields-module',
                                state: location.state.module as Modules
                            }}
                            className="nav_link"
                            >
                            <h5>{location.state.module.name} Module</h5>
                            </Link>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginRight:13, marginLeft:13}}>{location.state.fields.name}</h5>
                        <i className="bi bi-chevron-right"></i>
                        <h5 style={{marginLeft:13, opacity:0.6}}>{headerValue} Field</h5>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col xl={12}>
                        <Card style={{ border: 0 }}>
                            <CardBody >
                                <div className="row">
                                    <div className="col-2">
                                        <Nav tabs className="flex-column nav nav-pills">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer", backgroundColor: activeTab === "2" ? "white" : "#6c757d", color: activeTab === "2" ? "black" : "white" }}
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggle("1");
                                                    }}
                                                >
                                                    Edit Field
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer", backgroundColor: activeTab === "1" ? "white" : "#6c757d", color: activeTab === "1" ? "black" : "white" }}
                                                    className={classnames({
                                                        active: activeTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggle("2");
                                                    }}
                                                >
                                                    Source Field
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <div className="col-10">
                                        <TabContent activeTab={activeTab} className="text-muted">
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col>
                                                        <EditFieldTab />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col>
                                                        <SourceFieldTab mappingsField={mappingField} />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

