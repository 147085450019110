import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable, { SortOrder } from "react-data-table-component";
import { useLocation } from "react-router";
import { Form, Input } from "reactstrap";
import { DefaultThemeForReactTable } from "src/components/Table";
import { getPaginationRequestInitialValue, getUserModulesInitialValue, PaginationRequest, UserModules } from "src/sdk";
import { SuperAdminService } from "src/services/SuperAdminService";
import UserModuleActions from "./UserModuleActions";
import { UserModuleColumns } from "./Components/UserModulesColumns";
import UserModuleFilterFields from "./UserModuleFilterFields";

export interface ModulesSettingsProps {
    userId: string;
}

const service = new SuperAdminService();

export const ModulesSettings: React.FC<ModulesSettingsProps> = ({ userId }) => {
    const location = useLocation();

    ///all Modules
    const [userModules, setUserModules] = useState<UserModules>(getUserModulesInitialValue());
    const [userModulesData, setUserModulesData] = useState<UserModules[]>([]);

    /////pop up
    const [action_center, setAction_center] = useState(false);
    const [filter_center, setFilter_center] = useState(false);

    //////////pagination
    const [loading, setLoading] = useState(false);
    const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false);
    const [totalRows, setTotalRows] = useState(10000);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string>("");
    const [paginationData, setPaginationData] = useState<PaginationRequest>(getPaginationRequestInitialValue());
    const [sortBy, setSortBy] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    //////////////////pop up
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_actionCenter() {
        setAction_center(!action_center);
        removeBodyCss();
    }
    function tog_filterCenter() {
        setFilter_center(!filter_center);
        removeBodyCss();
    }
    const getActions = (value: UserModules) => {
        tog_actionCenter();
        setUserModules(value);
    }
    const getFilter = (value: UserModules) => {
        tog_filterCenter();
        setUserModules(value);
    }

    ///////////////search validation
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            search: ""
        },
        onSubmit: (values: any) => {
            fetchPage(page, values.search, true, sortBy, sortOrder);
            setSearch(values.search);
        }
    });

    ////////////////////////Pagination
    const fetchPage = async (page: any, search: any, paginationResetDefaultPage: any, sortBy: any, sortOrder: any) => {
        setLoading(true);
        let pagination: PaginationRequest = getPaginationRequestInitialValue();
        pagination.userId = userId;
        pagination.page = page;
        pagination.pageSize = perPage;
        pagination.search = search;
        pagination.sortBy = sortBy;
        pagination.sortOrder = sortOrder;

        setPaginationData(pagination);
        getUserModules(setUserModulesData, pagination, setTotalRows);
        setLoading(false);
        setPaginationResetDefaultPage(paginationResetDefaultPage);
    };

    useEffect(() => {
        fetchPage(page, "", false, sortBy, sortOrder); // fetch page 1 of users
    }, []);

    useEffect(() => {
    }, [userModules]);

    const handlePageChange = (pageNew: any) => {
        fetchPage(pageNew, search, false, sortBy, sortOrder);
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);
        let pagination: PaginationRequest = getPaginationRequestInitialValue();
        pagination.userId = userId;
        pagination.page = page;
        pagination.pageSize = newPerPage;
        pagination.search = search;
        pagination.sortBy = sortBy;
        pagination.sortOrder = sortOrder;

        setPaginationData(pagination);
        getUserModules(setUserModulesData, pagination, setTotalRows);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const sortPagination = (state: any, sortDirection: SortOrder) => {
        setSortOrder(sortDirection);
        setSortBy(state.id);
        fetchPage(page, search, false, state.id, sortDirection); // fetch page 1 of users
    }

    /////////////////////// Style Pagination
    const paginationComponentOptions = {
        rowsPerPageText: "Rows per page",
        rangeSeparatorText: "of",
        selectAllRowsItem: false,
    };

    ///////////////////////////////////////
    const asignedUserModule = (userModule: UserModules, check: boolean) => {
        let asignModule: UserModules = {
            userId: userId,
            moduleId: userModule.moduleId as number,
            moduleName: userModule.moduleName,
            assigned: check,
            userModuleId: userModule.userModuleId,
        }
        
        service.asignModule(asignModule)
            .then(function (response) {
                fetchPage(paginationData.page, paginationData.search, false, sortBy, sortOrder);
            })
            .catch(function (error) {
            });
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                {/* ////////////////////pop up window  for permission*/}
                <UserModuleActions
                    userModule={userModules}
                    action_center={action_center}
                    setAction_center={setAction_center}
                    tog_actionCenter={tog_actionCenter}
                />
                {/* ////////////////////pop up window  for filter*/}
                <UserModuleFilterFields
                    userModule={userModules}
                    filter_center={filter_center}
                    setFilter_center={setFilter_center}
                    tog_filterCenter={tog_filterCenter}
                />
                {/* //////////////////////////////////////search */}
                <div className="d-flex mb-5">
                    <div className="me-auto w-50">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <div className="form-group m-0">
                                <div className="input-group">
                                    <Input
                                        name="search"
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by module name ..."
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.search || ""}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-secondary rounded-0 rounded-end bi bi-search"
                                            type="submit"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    {/* ////////////////////////////////////////////////// */}
                </div>
                <div style={{ width: "100%" }} >
                    <div className="table-responsive">
                        <DataTable
                            data={userModulesData}
                            columns={UserModuleColumns(getActions, getFilter, asignedUserModule)}
                            customStyles={DefaultThemeForReactTable()}
                            ///////////////Pagination
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationResetDefaultPage={paginationResetDefaultPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            pointerOnHover={true}
                            onChangePage={handlePageChange}
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            onSort={sortPagination}
                            defaultSortAsc={false}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ModulesSettings;

////////Get all modules
export const getUserModules = (setUserModulesData: any, pagination: any, setTotalRows: any) => {
    service.getUserModules(pagination)
        .then(function (response) {
            setTotalRows(response.data.totalRecords);
            setUserModulesData(response.data.data);
        })
        .catch(function (error) {
        })
} 
