import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { User } from "src/sdk/models/User";

interface UserContextType {
    user: User | null;
    setUser: ((user: User | null) => void) | undefined;
}

export const UserContext = createContext<UserContextType>({
    user: null,
    setUser: undefined,
});

export const useUserContext = () => useContext(UserContext);
type Props = {  
    children: React.ReactNode; 
  };

export const UserProvider: React.FC<Props> = ({ children}) => {
    const [user, setUserState] = useState<User | null>(null);
    const setUser = useCallback(() => {
        return (user: User | null) => setUserState(user);
    }, [setUserState]);
    
    useEffect(() => {}, []);
    return (
        <UserContext.Provider
            value={{
                user: user,
                setUser: setUser(),
            }}>
            {children}
        </UserContext.Provider>
    );
};
