import React, { useEffect, useState } from "react";
import DataTable, { SortOrder, TableColumn } from "react-data-table-component";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Loader } from "src/components";
import { Actions, ActionsData, ExecutedActions, FieldForm, FieldValue, PaginationRequest, User, getFieldFormInitialValue, getPaginationRequestInitialValue } from "src/sdk";
import { UserService } from "src/services/UserService";
import Details from "./Details";
import DropdownExecutedActions from "./DropdownExecutedActions";
import { Form, Input, Label } from "reactstrap";
import { Field, Formik, useFormik } from "formik";
import { authRoutes } from "src/routes/allRoutes";
import { CookiesKey, getCookie } from "src/globals";
import { toast } from "react-toastify";
import ModuleActionPopup from "./ModuleActionPopup";

export interface FilterInput {
    Name?: string;
    Label: string;
    Type?: string;
    Value?: string;
    FieldName?: string;
    FieldKey?: string;
}

const service = new UserService();
export const DynamicModule = () => {
    const location = useLocation();

    ///Table
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState<TableColumn<any>[]>([]);
    const [columnsNames, setColumnsNames] = useState<FilterInput[]>([]);
    const [fieldsValuesData, setFieldsValuesData] = useState<any[]>([]);
    const [actions, setActions] = useState<any[]>([]);

    //Pgination
    const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false);
    const [totalRows, setTotalRows] = useState(10000);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");

    const [sortBy, setSortBy] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [paginationData, setPaginationData] = useState<PaginationRequest>(getPaginationRequestInitialValue());
    const [updateActions, setUpdateActions] = useState(false);

    /////Details pop up
    const [detailsItems, setDetailsItems] = useState<any[]>([]);
    const [detailsActions, setDetailsActions] = useState<any[]>([]);

    const [moduleRecordId, setModuleRecordId] = useState(0);

    const [obj, setObj] = useState<FieldValue>(getFieldFormInitialValue());
    const [modal_fullscreen, setmodal_fullscreen] = useState(false);
    const [isFull, setIsFull] = useState<boolean>(false);

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen);
        removeBodyCss();
    }

    ////Module Action popup
    const [selectedAction, setSelectedAction] = useState<Actions>(ActionsData[0]);
    const [openModuleActionModal, setOpenModuleActionModal] = useState<boolean>(false);

    const fetchPage = async (page: any, paginationResetDefaultPage: any, sortBy: any, sortOrder: any, search: any) => {
        setLoading(true);
        let pagination: PaginationRequest = getPaginationRequestInitialValue();
        pagination.page = page;
        pagination.pageSize = perPage;
        pagination.sortBy = sortBy;
        pagination.sortOrder = sortOrder;
        pagination.search = search;
        pagination.userModuleId = location.state.userModuleId;
        const userJsonValues = getCookie(CookiesKey.USER_COOKIE);
        if (userJsonValues) {
            const user = JSON.parse(userJsonValues) as User;
            pagination.externalUserId = user.stuffId;
        }
        setPaginationData(pagination);
        
        ///////////////////////////
        service.moduleRecordsByUserModuleId(pagination)
            .then(function (response) {
                setTotalRows(response.data.totalRecords);
                setFieldsValuesData([]);
                
                setActions(response.data.actions);

                //////////HEADER FOR COLUMNS
                let l: TableColumn<any>[] = [];
                let m: FilterInput[] = [];
                if (response.data.data[0] !== undefined) {
                    response.data.data[0].sort((a: { orderNo: number; }, b: { orderNo: number; }) => (a.orderNo > b.orderNo ? 1 : -1)).forEach((element: any) => {
                        let header1: TableColumn<any> = {
                            selector: (row: any) => row[element.name],
                            sortable: element.sortable,
                            name: element.label,
                            sortField: element.filedKey
                        }
                        l.push(header1);

                        let filterInput: FilterInput = {
                            Label: "Enter " + element.name,
                            Type: element.type,
                            FieldName: element.name,
                            FieldKey: element.filedKey
                        }
                        m.push(filterInput);
                    });
                }

                response.data.data.forEach((element: any, index: any) => {
                    let header2: TableColumn<any> = {
                        cell: (params, index, column, row) => {
                            if(response.data.data[index] !== undefined){
                                
                                return <>
                                    <div style={{ paddingRight: "20px" }} >
    
                                        <button
                                            style={{backgroundColor:"#95CBB2", color:"#FFFFFF", border:0, borderRadius:60, gap:5, width:110, paddingTop:10, paddingBottom:10, paddingLeft: 25, paddingRight:25, display:"flex"}}
                                            type="button"
                                            onClick={() => {
                                                setModuleRecordId(response.data.data[index][0].moduleRecordId);
                                                ///////////////send moduleRecordId to Details
                                                service.getModuleRecordsByModuleRecordId(response.data.data[index][0].moduleRecordId)
                                                    .then(function (response) {
                                                        setDetailsItems(response.data.item.fieldValueItems);
                                                        setDetailsActions(response.data.actions);
    
                                                        const obj1: FieldForm = {};
                                                        response.data.item.fieldValueItems.map((e: any) => {
                                                            let key: string = e.name;
                                                            obj1[key] = e.value;
                                                        });
                                                        setObj(obj1);
                                                        ///////////////////////
                                                        setIsFull(true);
                                                    })
                                                    .catch(function (error) {
                                                    })
                                                ////////////////////                                     
                                            }}
                                        >
                                            <i className={`bi-list`}/>
                                            Details
                                        </button>
                                    </div>
                                    <div>
                                        <DropdownExecutedActions
                                            moduleRecordId={response.data.data[index][0].moduleRecordId}
                                            actions={response.data.actions}
                                            setUpdateActions={setUpdateActions}
                                        />
                                    </div>
                                </>
                            }
                        },
                        width: "25%",
                    }
                    if (index === 0) {
                        l.push(header2);
                    }
                });
                setColumns(l);
                setColumnsNames(m);

                ///// ADD DATA TO DYNAMIC OBJECT
                let v: FieldValue[] = [];
                for (let i = 0; i < response.data.data.length; i++) {
                    const obj: FieldValue = {};
                    response.data.data[i].forEach((element: any) => {
                        let key: string = element.name;
                        obj[key] = element.value;
                    });
                    v.push(obj);
                }
                setLoading(false);
                setFieldsValuesData(v);
            })
            .catch(function (error) {
            })

        ////////////////////
        setPaginationResetDefaultPage(paginationResetDefaultPage);
    };

    useEffect(() => {
        if (isFull === true) {
            setIsFull(false);
            tog_fullscreen();
        }
    }, [isFull]);

    useEffect(() => {
        fetchPage(page, false, "", "", null); // fetch page 1 of users
    }, [location.state]);

    useEffect(() => {
        if (updateActions === true) {
            fetchPage(paginationData.page, false, sortBy, sortOrder, search); // fetch page 1 of users
            setUpdateActions(false);
        }
    }, [updateActions]);

    const handlePageChange = (pageNew: any) => {
        fetchPage(pageNew, false, sortBy, sortOrder, search);
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);
        let pagination: PaginationRequest = getPaginationRequestInitialValue();
        pagination.page = page;
        pagination.pageSize = newPerPage;
        pagination.sortBy = sortBy;
        pagination.sortOrder = sortOrder;
        pagination.userModuleId = location.state.userModuleId;
        setPaginationData(pagination);

        ///////////////////////////
        service.moduleRecordsByUserModuleId(pagination)
            .then(function (response) {
                setFieldsValuesData([]);
                //////////HEADER FOR COLUMNS
                let l: TableColumn<any>[] = [];
                if (response.data.data[0] !== undefined) {
                    response.data.data[0].sort((a: { orderNo: number; }, b: { orderNo: number; }) => (a.orderNo > b.orderNo ? 1 : -1)).forEach((element: any) => {
                        let header1: TableColumn<any> = {
                            selector: (row: any) => row[element.name],
                            sortable: element.sortable,
                            name: element.label,
                            sortField: element.filedKey
                        }
                        l.push(header1);
                    });
                }

                response.data.data.forEach((element: any, index: any) => {
                    let header2: TableColumn<any> = {
                        cell: (params, index, column, row) => {
                            return <>
                                <div style={{ paddingRight: "20px" }} >

                                    <button
                                        style={{backgroundColor:"#95CBB2", color:"#FFFFFF", border:0, borderRadius:60, gap:5, width:110, paddingTop:10, paddingBottom:10, paddingLeft: 25, paddingRight:25, display:"flex"}}
                                        type="button"
                                        onClick={() => {
                                            setModuleRecordId(response.data.data[index][0].moduleRecordId);

                                            ///////////////send moduleRecordId to Details
                                            service.getModuleRecordsByModuleRecordId(response.data.data[index][0].moduleRecordId)
                                                .then(function (response) {
                                                    setDetailsItems(response.data.item.fieldValueItems);
                                                    setDetailsActions(response.data.actions);

                                                    const obj1: FieldForm = {};
                                                    response.data.item.fieldValueItems.map((e: any) => {
                                                        let key: string = e.name;
                                                        obj1[key] = e.value;
                                                    });

                                                    setObj(obj1);
                                                    ///////////////////////
                                                    setIsFull(true);
                                                })
                                                .catch(function (error) {
                                                })
                                            ////////////////////                                        
                                        }}
                                    >
                                        <i className={`bi-list`}/>
                                            Details
                                    </button>
                                </div>
                                <div>
                                    <DropdownExecutedActions
                                        moduleRecordId={response.data.data[index][0].moduleRecordId}
                                        actions={response.data.actions}
                                        setUpdateActions={setUpdateActions}
                                    />
                                </div>
                            </>
                        },
                        width: "25%",
                    }
                    if (index === 0) {
                        l.push(header2);
                    }
                });
                setColumns(l);

                ///// ADD DATA TO DYNAMIC OBJECT
                let v: FieldValue[] = [];
                for (let i = 0; i < response.data.data.length; i++) {
                    const obj: FieldValue = {};
                    response.data.data[i].forEach((element: any) => {
                        let key: string = element.name;
                        obj[key] = element.value;
                    });
                    v.push(obj);
                }
                setLoading(false);
                setFieldsValuesData(v);
            })
            .catch(function (error) {
            })
        ////////////////////
        setPerPage(newPerPage);
    };

    const sortPagination = (state: any, sortDirection: SortOrder) => {
        setSortOrder(sortDirection);
        setSortBy((state.sortField).toString());
        fetchPage(page, false, (state.sortField).toString(), sortDirection, search); // fetch page 1 of users
    }

    /////////////////////// Style Pagination
    const paginationComponentOptions = {
        rowsPerPageText: "Rows per page",
        rangeSeparatorText: "of",
        selectAllRowsItem: false,
    };

    

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: obj,

        onSubmit: (values: any) => {
            let m: FilterInput[] = [];
            Object.keys(values).forEach((element: any) => {
                if(element !== "")
                {
                    let filterFieldName = element.substring(0, element.indexOf("|"))
                    let field = columnsNames.find(x => x.FieldName === filterFieldName);
                    let filterInput: FilterInput = {
                        Name: element,
                        Label: "Enter " + element,
                        Type: field?.Type,
                        FieldKey: field?.FieldKey,
                        FieldName: field?.FieldName,
                        Value: values[element]
                    }
                    m.push(filterInput);
                }
            });
            
            setSearch(JSON.stringify(m));
            fetchPage(page, false, sortBy, sortOrder, JSON.stringify(m));
        }
    });

    const clearFilters = () => {
        setSearch("[]");
        setObj(getFieldFormInitialValue());
        setColumnsNames([]);
        fetchPage(1, false, sortBy, sortOrder, null);
        validation.resetForm();
    };

    ///////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div style={{ display: "flex", flexDirection:"row", marginBottom: 10, gap: 5 }}>
                    {actions.filter(a => a.isModuleAction === true).map((element: any, index: any) => {
                                return (
                                    <div key={index} style={{ marginLeft: 0, marginRight: 0, marginTop: 5 }}>
                                        <button key={index}
                                            style={{ borderRadius: 10, border: 0, backgroundColor: "#95CBB2", color: "#FFFFFF" }}
                                            className="btn btn-primary btn-block"
                                            type="button"
                                            onClick={() => {
                                                    setSelectedAction(element);
                                                    setOpenModuleActionModal(true);
                                                }
                                            }
                                        >
                                            {element.name}
                                        </button>
                                    </div>
                                );
                            })}
                </div>
                <div>
                    <h3>{location.state.moduleName}</h3>
                </div>
                {/* /////////////Details Pop up */}
                <Details
                    obj={obj}
                    modal_fullscreen={modal_fullscreen}
                    setmodal_fullscreen={setmodal_fullscreen}
                    tog_fullscreen={tog_fullscreen}
                    detailsItems={detailsItems}
                    detailsActions={detailsActions.filter(a => a.isModuleAction === false)}
                    moduleRecordId={moduleRecordId}
                    setUpdateActions={setUpdateActions}
                />

                <ModuleActionPopup
                    openModal={openModuleActionModal}
                    setOpenModal={setOpenModuleActionModal}
                    action={selectedAction}
                    properties={columnsNames}
                />
                {/* ////////////////// */}
                <div className="table-responsive">
                <Form
                            onSubmit={(e: any) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                        <div style={{display:"flex", flexDirection: "row", alignItems: "flex-end", width: "100%"}}>
                            <div style={{display:"flex", flexDirection: "row", alignItems: "flex-end", width: "75%"}}>
                            {columnsNames.map((val, index) => {
                            return(
                            <div key={index} style={{width:100/columnsNames.length+"%"}}>
                                <Label className="form-label">{val.Name}</Label>
                                <>
                                {
                                    val.Type == "date" ?
                                    <>
                                    <Input
                                        name={val.FieldName + "|From"}
                                        placeholder="From"
                                        onChange={validation.handleChange}
                                        type="text"
                                        style={{borderRadius: 0, fontSize: 12}}
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                    <Input
                                        name={val.FieldName + "|To"}
                                        placeholder="To"
                                        onChange={validation.handleChange}
                                        type="text"
                                        style={{borderRadius: 0, fontSize: 12}}
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                    />
                                    </>
                                    :
                                    <Input
                                        name={val.FieldName + "|"}
                                        placeholder="Search..."
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        type="text"
                                        style={{borderRadius: 0, fontSize: 12}}
                                    />
                                }
                                </>
                                
                            </div>
                            )}
                        )}
                            </div>
                        <div style={{ marginLeft: "20px", paddingBottom:5, paddingRight: "20px" }} >
    
                                <input className="btn btn-block" 
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = 'transparent';
                                        e.currentTarget.style.color = 'black';
                                    }}
                                    style={{borderColor:"#95CBB2", borderRadius:60, paddingLeft:10, paddingRight:10, maxHeight:25, fontSize:15, paddingTop:0, paddingBottom:0}} 
                                    type="submit" 
                                    value="Filter" />
                                    </div>
                                    <div style={{paddingBottom:5}} >
                                    <input className="btn btn-block" 
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.color = 'white';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = 'transparent';
                                        e.currentTarget.style.color = 'black';
                                    }}
                                    onClick={() => {clearFilters()}}
                                    style={{borderColor:"#95CBB2", borderRadius:60, paddingLeft:10, paddingRight:10, maxHeight:25, fontSize:15, paddingTop:0, paddingBottom:0}} 
                                    type="button" 
                                    value="Clear" />
                                    </div>
                    </div>
                </Form>
                
                    <DataTable
                        data={fieldsValuesData}
                        columns={columns}
                        customStyles={DefaultThemeForReactTable()}
                        progressPending={loading}
                        progressComponent={<Loader loading={loading} />}
                        ///////////////Pagination
                        pagination
                        paginationServer
                        sortServer
                        paginationTotalRows={totalRows}
                        paginationResetDefaultPage={paginationResetDefaultPage}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pointerOnHover={true}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                        onSort={sortPagination}
                        defaultSortAsc={false}
                        responsive={false}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

DynamicModule.propTypes = {

};
export default withTranslation()(DynamicModule);

const DefaultThemeForReactTable = () => ({
    table: {
      style: {
      },
    },
    headRow: {
      style: {
        fontSize: 12,
        height: "40px",
        minHeight: "40px"
      },
    },
    headCells: {
      style: {
        width: "10px",
        borderLeft: `1px solid #dee2e6`,
        borderRight: `1px solid #dee2e6`,
        backgroundColor: "#274755",
        color: "#FFFFFF",

        height: "40px",
        maxHeight: "40px"
      },
    },
    rows: {
      style: {
        borderBottomWidth: "0px !important",
      },
    },
    cells: {
      style: {
        width: "10px",
        height: "50px",
        fontSize: 12,
        border: "1px solid #dee2e6",
        fontWeight: "bold"
      },
    },
    pagination: {
      style: {
      },
    },
  
  });

