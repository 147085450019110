import React from "react";
import { withTranslation } from "react-i18next";
import '../Style/index.css';
import ProfileMenu from "./ProfileMenu";

export interface AppHeaderProps {
  showNav: boolean;
  setShowNav: (showNav: boolean) => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({
  showNav,
  setShowNav,
}) => {

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand navbar-light border-bottom border-light" >
        <div className="container-fluid ">
          <div className="header_toggle">
            <i
              className={`bi ${showNav ? 'bi-x' : 'bi-list'}`}
              onClick={() => setShowNav(!showNav)} />
          </div>
          <div className="d-flex">
            <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
              <li className="nav-item">
                <ProfileMenu />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

AppHeader.propTypes = {
};

export default withTranslation()(AppHeader);
