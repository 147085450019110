import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { Actions } from "src/sdk";
import ActionsDropdown from "./DropdownComponent";

export const ActionsModuleColumns = memoizeOne(
    (
        onEditAction: (Values: Actions) => void,
        onDeleteAction: (Id: number) => void,
    ): TableColumn<Actions>[] => {
        return [
            {
                id: "actionId",
                selector: (row: any) => row.actionId,
                sortable: true,
                name: "Id",
            },
            {
                id: "name",
                selector: (row: any) => row.name,
                sortable: true,
                name: "Name",
            },
            {
                id: "link",
                selector: (row: any) => row.link,
                sortable: true,
                name: "Link",
            },
            {
                id: "actionType",
                selector: (row: any) => row.actionType,
                sortable: true,
                name: "Type",
            },
            {
                id: "showOnList",
                sortable: true,
                name: "Show on list",
                cell: (row: any) => {
                    return (
                        <div className="m-auto h4">
                            {
                                row.showOnList === true ? <span className=" bi bi-check-lg green-color"> </span> : <span className=" bi bi-x red-color"> </span>
                            }
                        </div>
                    );
                },
            },
            {
                id: "showOnForm",
                sortable: true,
                name: "Show on form",
                cell: (row: any) => {
                    return (
                        <div className="m-auto h4">
                            {
                                row.showOnForm === true ? <span className=" bi bi-check-lg green-color"> </span> : <span className=" bi bi-x red-color"> </span>
                            }
                        </div>
                    );
                },
            },
            {
                cell: (row: any) => {
                    return (
                        <div className="m-auto">
                            <ActionsDropdown
                                values={row as Actions}
                                id={row.actionId as number}
                                onEditAction={onEditAction}
                                onDeleteAction={onDeleteAction}
                            />
                        </div>
                    );
                },
                width: "150px",
            },
        ];
    },
);