import axios from "axios";
import Cookies from "js-cookie";
import { apiRoutesForContext } from "src/routes/apiRoutes/react-api-route-paths";
import { ChangePassword, Users } from "src/sdk";
import { BaseService } from "../services/BaseService";

const tmp = apiRoutesForContext();
export class ProfileService extends BaseService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }

    changePassword(data: ChangePassword): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.profile().changePassword(),
            data: data
        })
    }

    getUserById(userId: string): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.profile().getUser() + "/" + userId,
        })
    }

    updateUser(data: Users): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.profile().updateUser(),
            data: data
        })
    }
}
