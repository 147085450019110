import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { Users } from "src/sdk";
import DropdownUsersManagment from "./DropdownComponent";

export const UsersColumns = memoizeOne(
    (
        onEditUser: (values: Users) => void,
        onDeleteUser: (userId: string) => void,
        isApprovedRegistrationUser: (userId: string) => void,
    ): TableColumn<Users>[] => {
        return [
            {
                id: "firstName",
                selector: (row: any) => row.firstName,
                sortable: true,
                name: "First name",
            },
            {
                id: "lastName",
                selector: (row: any) => row.lastName,
                sortable: true,
                name: "Last name",
            },
            {
                id: "email",
                selector: (row: any) => row.email,
                sortable: true,
                name: "Email",
                width: "300px",
            },
            {
                id: "role",
                selector: (row: any) => row.role,
                sortable: true,
                name: "Role",
            },
            {
                id: "externalUserId",
                selector: (row: any) => row.externalUserId,
                sortable: true,
                name: "ExternalUserId",
            }
            ,
            {
                id: "externalUserRole",
                selector: (row: any) => row.externalUserRole,
                sortable: true,
                name: "ExternalUserRole",
            },
            {
                cell: (row: any) => {
                    return (<div className="m-auto">
                        {row.isApproved ?
                            (<div>

                                <DropdownUsersManagment
                                    values={row as Users}
                                    userId={row.userId as string}
                                    onEditUser={onEditUser}
                                    onDeleteUser={onDeleteUser}
                                />
                            </div>)
                            : (
                                <div>
                                    <button
                                        className="btn btn-success btn-block"
                                        style={{border:0, backgroundColor:"#95CBB2", color:"#FFFFFF"}}
                                        onClick={() => {
                                            isApprovedRegistrationUser(row.userId)
                                        }}
                                    >
                                        Approve
                                    </button>
                                </div>)}

                    </div>
                    );
                },
                width: "140px",
            },
        ];
    },
);