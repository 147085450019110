import React from "react";
import { withTranslation } from "react-i18next";
import { ColorRing } from 'react-loader-spinner';

export interface LoaderProps {
    loading: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ loading }) => {

    return (
        <React.Fragment>
            <div className="container-fluid">
                <ColorRing
                    visible={loading}
                    height="120"
                    width="120"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    wrapperClass="blocks-wrapper"
                    colors={['#0275d8', '#0275d8', '#0275d8', '#0275d8', '#0275d8']}
                />
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(Loader);

